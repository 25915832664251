import React, { useEffect, useState } from "react";
import girl_png from "../Images/girl_png.png";
import Ellipse_1 from "../Images/Ellipse_1.png";
import location_icon from "../Images/location_icon.png";
import clock_icon from "../Images/clock_icon.png";
import account_icon from "../Images/account_icon.png";
import from_to from "../Images/from_to.png";
import from_to_red from "../Images/from_to_red.png";
import Navbar from "./Navbar";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CarLoader from "./carLoader";
import NoRecord from "./Com/NoRecord";

export const configJSON = require("../Components/Config");

function UserRide() {
  const navigate = useNavigate();
  const [userRides, setUserRides] = useState([]);
  const [riderDetails, setRiderDetails] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [filterData, setFilterData] = useState("all");
  const userID = useLocation().state.id;
  const [userData, setUserData] = useState([]);
  const [active_rides, setActive_rides] = useState();
  const [searchData, setSearchData] = useState([]);
  const [searchDataByUser, setSearchDataByUser] = useState();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      navigate("/login");
    } else {
      getAllUserRides();
      getUser();
    }
  }, []);

  const getUser = () => {
    setIsLoader(true);
    const data = {
      user_id: `${userID}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.get_users_detail,
      method: "post",
      data: data,
    })
      .then((res) => {
        setUserData(res.data.data[0]);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };
  const getallRideDetails = (id) => {
    setIsLoader(true);
    const data = {
      user_id: `${id}`,
    };
    setActive_rides(id);
    axios({
      url: configJSON.baseUrl + configJSON.get_user_ride_detail,
      method: "post",
      data: data,
    })
      .then((res) => {
        setIsLoader(false);
        setRiderDetails(res.data.data[0]);
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };
  const getAllUserRides = () => {
    const data = {
      user_id: `${userID}`,
    };
    setIsLoader(true)
    axios({
      url: configJSON.baseUrl + configJSON.get_user_rides,
      method: "post",
      data: data,
    })
      .then((res) => {
        setUserRides(res.data.data);
        setActive_rides(res.data.data[0].id);
        getallRideDetails(res.data.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };

  const filterUserData = (val) => {
    setFilterData(val);
    const dummyData = [];

    if (val != "all") {
      userRides?.map((item) => item?.status == val && dummyData.push(item));
      setSearchData(dummyData);
      getallRideDetails(dummyData[0]?.id);
      setActive_rides(dummyData[0]?.id);
    } else {
      const newDummyData = [];
      userRides?.map((item) => newDummyData.push(item));
      setSearchData(newDummyData);
      getallRideDetails(newDummyData[0]?.id);
      setActive_rides(newDummyData[0]?.id);
    }
  };

  const onHandaleSearchvalue = (e) => {
    setSearchData([]);
    setFilterData("all");
    setSearchDataByUser(e.target.value);
    const data = userRides.filter(
      (item) =>
        item?.driver_data?.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) && searchData.push(item)
    );
    setSearchData(data);
    getallRideDetails(searchData[0]?.id);
    setUserRides(searchData);

    setActive_rides(searchData[0]?.id);
    setSearchData([]);
    if (e.target.value == "") getAllUserRides();
  };
  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar data="/userride" />
        <div className="container-fluid ct_margin_top">
          <div className="row">
            <div className="col-md-6">
              <div className="ct_search_bar">
                <input
                  type="text"
                  className="form-control"
                  value={searchDataByUser}
                  onChange={(e) => onHandaleSearchvalue(e)}
                  placeholder="Search"
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <div className="ct_choose_rides_dot">
                <ul>
                  <li onClick={() => filterUserData("all")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "all" ? true : false}
                      name="radio-group"
                      id="radio1"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio1">
                      <span className="ct_radio-inner-circle"></span>
                      All Users
                    </label>
                  </li>
                  <li onClick={() => filterUserData("1")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "1" ? true : false}
                      name="radio-group"
                      id="radio2"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio2">
                      <span className="ct_radio-inner-circle"></span>
                      Active
                    </label>
                  </li>
                  <li onClick={() => filterUserData("2")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "2" ? true : false}
                      name="radio-group"
                      id="radio4"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio4">
                      <span className="ct_radio-inner-circle"></span>
                      Blocked
                    </label>
                  </li>
                  <li onClick={() => filterUserData("3")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "3" ? true : false}
                      name="radio-group"
                      id="radio3"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio3">
                      <span className="ct_radio-inner-circle"></span>
                      Suspended
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mb-5 mt-4">
            <h4 className="mb-0">All Rides Of</h4>
            <div className="d-flex align-items-center flex-wrap gap-3 ct_greay_img_bg">
              <div className="ct_user_img_circle">
                <img
                  src={girl_png}
                  alt="img/girl_png.png"
                  className="ct_small_img_40"
                />
              </div>
              <div className="d-flex align-items-center gap-4">
                <div className="ct_user_info_name">
                  <h5>{userData.name}</h5>
                  <small className="text-dark">{userData.phone_no}</small>
                </div>
              </div>
            </div>
          </div>

          {isLoader ? (
            <CarLoader />
          ) : (
            <div className="row mt-5">
              <div className="col-lg-8 mb-4">
                <div className="ct_active_rides_cnt">
                  <div className="ct_activre_rides_all_boxes">
                    {filterData !== "all"
                      ? userRides?.map(
                          (obj) =>
                            obj.status == filterData && (
                              <div
                                className={
                                  active_rides == obj?.id
                                    ? "ct_active_ride_card ct_card_active"
                                    : "ct_active_ride_card"
                                }
                                onClick={() => {
                                  getallRideDetails(obj.id);
                                }}
                              >
                                <div className="d-flex align-items-center flex-wrap gap-5">
                                  <div className="d-flex align-items-center flex-wrap gap-3">
                                    <div className="ct_user_img_circle">
                                      {obj?.driver_data?.profile_image != "" &&
                                      obj?.driver_data?.profile_image != "0" ? (
                                        <img
                                          src={obj?.driver_data?.profile_image}
                                        />
                                      ) : (
                                        <img
                                          src={Ellipse_1}
                                          alt="img/Ellipse_1.png"
                                        />
                                      )}
                                    </div>
                                    <div className="d-flex align-items-center gap-4">
                                      <div className="ct_user_info_name">
                                        <h5>{obj?.driver_data?.name}</h5>
                                        <small>
                                          {obj?.driver_data?.car_model}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ct_rides_date">
                                    <span>{obj.dateOfBooking}</span>
                                    <span>{obj.time}</span>
                                  </div>
                                  {/* <div className="ct_location_list">
                                                    <ul>
                                                        <li>
                                                            <img src={location_icon} alt="img/location_icon.png" />
                                                            <p>{riderDetails?.distance}</p>
                                                        </li>
                                                        <li>
                                                            <img src={clock_icon} alt="img/clock_icon.png" />
                                                            <p>{riderDetails?.duration}</p>
                                                        </li>
                                                        <li>
                                                            <img src={account_icon} alt="img/account_icon.png" />
                                                            <p>$7.00</p>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                </div>
                                <div className="ct_active_link">
                                  {/* <a className="ct_yellow_text">Active</a> */}
                                  {obj.status == 1 && (
                                    <a className="ct_yellow_text">Active</a>
                                  )}
                                  {obj.status == 2 && (
                                    <a className="ct_yellow_text">Blocked</a>
                                  )}
                                  {obj.status == 3 && (
                                    <a className="ct_yellow_text">Suspended</a>
                                  )}
                                </div>
                              </div>
                            )
                        )
                      : userRides?.map((obj) => (
                          <div
                            className={
                              active_rides == obj?.id
                                ? "ct_active_ride_card ct_card_active"
                                : "ct_active_ride_card"
                            }
                            onClick={() => {
                              getallRideDetails(obj.id);
                            }}
                          >
                            {console.log(obj.status)}
                            <div className="d-flex align-items-center flex-wrap gap-5">
                              <div className="d-flex align-items-center flex-wrap gap-3">
                                <div className="ct_user_img_circle">
                                  {obj?.driver_data?.profile_image != "" &&
                                  obj?.driver_data?.profile_image != "0" ? (
                                    <img
                                      src={obj?.driver_data?.profile_image}
                                    />
                                  ) : (
                                    <img
                                      src={Ellipse_1}
                                      alt="img/Ellipse_1.png"
                                    />
                                  )}
                                </div>
                                <div className="d-flex align-items-center gap-4">
                                  <div className="ct_user_info_name">
                                    <h5>{obj?.driver_data?.name}</h5>
                                    <small>{obj?.driver_data?.car_model}</small>
                                  </div>
                                </div>
                              </div>
                              <div className="ct_rides_date">
                                <span>{obj.dateOfBooking}</span>
                                <span>{obj.time}</span>
                              </div>
                              {/* <div className="ct_location_list">
                                                        <ul>
                                                            <li>
                                                                <img src={location_icon} alt="img/location_icon.png" />
                                                                <p>{riderDetails?.distance}</p>
                                                            </li>
                                                            <li>
                                                                <img src={clock_icon} alt="img/clock_icon.png" />
                                                                <p>{riderDetails?.duration}</p>
                                                            </li>
                                                            <li>
                                                                <img src={account_icon} alt="img/account_icon.png" />
                                                                <p>$7.00</p>
                                                            </li>
                                                        </ul>
                                                    </div> */}
                            </div>

                            <div className="ct_active_link">
                              {obj.status == 1 && (
                                <a className="ct_yellow_text">Active</a>
                              )}
                              {obj.status == 2 && (
                                <a className="ct_yellow_text">Blocked</a>
                              )}
                              {obj.status == 3 && (
                                <a className="ct_yellow_text">Suspended</a>
                              )}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="ct_active_rides_cnt">
                  <h4 className="text-center ">Ride Details</h4>
                </div>
                {filterData == "all" && userRides?.length != 0 ? (
                  <div className="ct_rides_detail_box">
                    <span className="ct_grey_text">Driver</span>
                    <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                      <div className="d-flex align-items-center flex-wrap gap-3 ">
                        <div className="ct_user_img_circle">
                          {riderDetails?.driver_data?.profile_image != "" &&
                          riderDetails?.driver_data?.profile_image != "0" ? (
                            <img
                              src={riderDetails?.driver_data?.profile_image}
                            />
                          ) : (
                            <img src={Ellipse_1} alt="img/Ellipse_1.png" />
                          )}
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>{riderDetails?.driver_data?.name}</h5>
                            <small>
                              {riderDetails?.driver_data?.car_model} <br />
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        {/* <a className="text-danger">Cancelled</a> */}
                        {riderDetails.status == 1 && (
                          <a className="text-danger">Active</a>
                        )}
                        {riderDetails.status == 2 && (
                          <a className="text-danger">Blocked</a>
                        )}
                        {riderDetails.status == 3 && (
                          <a className="text-danger">Suspended</a>
                        )}
                      </div>
                    </div>
                    <div className="ct_border_btoom  py-3">
                      <div className="ct_location_list ">
                        <ul className="justify-content-between w-100 ct_mobile_wrap_list">
                          <li>
                            <img
                              src={location_icon}
                              alt="img/location_icon.png"
                            />
                            <p>{riderDetails?.distance}</p>
                          </li>
                          <li>
                            <img src={clock_icon} alt="img/clock_icon.png" />
                            <p>{riderDetails?.duration}</p>
                          </li>
                          {/* <li>
                                                    <img src={account_icon} alt="img/account_icon.png" />
                                                    <p>$7.00</p>
                                                </li> */}
                        </ul>
                      </div>
                      <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                        <p className="mb-0 ct_grey_text">Date & Time</p>
                        <p className="mb-0">
                          {riderDetails.dateOfBooking} | {riderDetails.time}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap mt-3  ">
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>Current Location</h5>
                          <small>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-geo-alt"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            {riderDetails.start}
                          </small>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        <img src={from_to} alt="img/from_to.png" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap  ">
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>Soft Bank Buildings</h5>
                          <small>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-geo-alt"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>{" "}
                            {riderDetails.destination}
                          </small>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        <img src={from_to_red} alt="img/from_to_red.png" />
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="ct_grey_text mb-3 d-block">User</span>
                      <div className="d-flex align-items-center flex-wrap gap-3">
                        <div className="ct_user_img_circle">
                          <img src={girl_png} alt="img/girl_png.png" />
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>{riderDetails.name}</h5>
                            <small>{riderDetails.phone_no}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  filterData == riderDetails?.status &&
                  userRides?.length != 0 && (
                    <div className="ct_rides_detail_box">
                      <span className="ct_grey_text">Driver</span>
                      <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                        <div className="d-flex align-items-center flex-wrap gap-3 ">
                          <div className="ct_user_img_circle">
                            <img src={Ellipse_1} alt="img/Ellipse_1.png" />
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.driver_data?.name}</h5>
                              <small>
                                {riderDetails?.driver_data?.car_model} <br />
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          {/* <a className="text-danger">Cancelled</a> */}
                          {riderDetails.status == 1 && (
                            <a className="text-danger">Active</a>
                          )}
                          {riderDetails.status == 2 && (
                            <a className="text-danger">Blocked</a>
                          )}
                          {riderDetails.status == 3 && (
                            <a className="text-danger">Suspended</a>
                          )}
                        </div>
                      </div>
                      <div className="ct_border_btoom  py-3">
                        <div className="ct_location_list ">
                          <ul className="justify-content-between w-100 ct_mobile_wrap_list">
                            <li>
                              <img
                                src={location_icon}
                                alt="img/location_icon.png"
                              />
                              <p>{riderDetails?.distance}</p>
                            </li>
                            <li>
                              <img src={clock_icon} alt="img/clock_icon.png" />
                              <p>{riderDetails?.duration}</p>
                            </li>
                            {/* <li>
                                                    <img src={account_icon} alt="img/account_icon.png" />
                                                    <p>$7.00</p>
                                                </li> */}
                          </ul>
                        </div>
                        <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                          <p className="mb-0 ct_grey_text">Date & Time</p>
                          <p className="mb-0">
                            {riderDetails.dateOfBooking} | {riderDetails.time}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between flex-wrap mt-3  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Current Location</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails.start}
                            </small>
                          </div>
                        </div>

                        <div className="ct_active_link">
                          <img src={from_to} alt="img/from_to.png" />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between flex-wrap  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Soft Bank Buildings</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>{" "}
                              {riderDetails.destination}
                            </small>
                          </div>
                        </div>

                        <div className="ct_active_link">
                          <img src={from_to_red} alt="img/from_to_red.png" />
                        </div>
                      </div>

                      <div className="mt-4">
                        <span className="ct_grey_text mb-3 d-block">User</span>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <div className="ct_user_img_circle">
                            <img src={girl_png} alt="img/girl_png.png" />
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails.name}</h5>
                              <small>{riderDetails.phone_no}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {searchData?.length == 0 && filterData != "all" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  < NoRecord lod={isLoader}/>
                </div>
              ) : (
                userRides?.length == 0 &&
                filterData == "all" && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    < NoRecord lod={isLoader}/>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default UserRide;
