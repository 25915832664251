import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Rides from './Components/Rides';
import Setting from './Components/Setting';
import UserRide from './Components/UserRide';
import Users from './Components/Users';
import ChangePassword from './Components/ChangePassword';
import Login from './Components/Login';
import ForgetPassword from './Components/ForgetPassword';
import Dashboard from './Components/Dashboard';
import Cabs from './Components/Cabs';
import BookRides from './Components/web_user/BookRides';
import VerifyRide from './Components/web_user/VerifyRide';
import UserProfile from './Components/web_user/UserProfile';
import AllBooking from './Components/web_user/AllBooking';
import EditUser from './Components/web_user/EditUser';
import Location from './Components/Location';
import Mayank from './Components/mayank';
import Support from './Support';
import Category from './Components/Category';


function App() {

  return (
    <div className="App">
      <Router basename='/'>
        <Routes>
          <Route exact path='/' element={<Dashboard />} />
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path='/rides' element={<Mayank />}></Route>
          {/* <Route exact path='/mayank' element={<Rides />} /> */}
          <Route exact path='/settings' element={<Setting />}></Route>
          <Route exact path='/userride' element={<UserRide />}></Route>
          <Route exact path='/users' element={<Users />}></Route>
          <Route exact path='/changepassword' element={<ChangePassword />}></Route>
          <Route exact path='/forgetpassword' element={<ForgetPassword />}></Route>
          <Route exact path='/cabs' element={<Cabs />} />
          <Route exact path='/book_ride' element={<BookRides />} />
          <Route exact path='/otp_verification' element={<VerifyRide />} />
          <Route exact path='/user_profile' element={<UserProfile />} />
          <Route exact path='/all_booking' element={<AllBooking />} />
          <Route exact path='/edit_profile' element={<EditUser />} />
          <Route exact path='/location' element={<Location />} />
          <Route exact path='/support' element={<Support />} />
          <Route exact path='/category' element={<Category />} />
         
        </Routes>
      </Router>
    </div>
  );
}

export default App;