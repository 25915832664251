import React from 'react'
import qr_cab_png from "../Images/qr_cab.png"
import logout_png from "../Images/logout.png"
import { useNavigate } from "react-router-dom";
import { message, message as MESSAGE } from "antd";

const Navbar = (props) => {
    const navigate = useNavigate()
    const cursor = { cursor: 'pointer' }
    const isActive = props.data

    const onHandleNavbar = (val) => {
        navigate(`/${val}`)
    }
    const logOutPage = () => {
        localStorage.clear();
        MESSAGE.success('User logout successfull');
        navigate("/login")
    }

    return (
        <div className='ct_h_173'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ct_header_bg">
                            <div className="ct_top_head">
                                <div className="ct_login_logo">
                                    <img src={qr_cab_png} alt="img/qr_cab.png" />
                                </div>
                                <div className="ct_logout_icon">
                                    <a >
                                        <img src={logout_png} onClick={logOutPage} alt="img/logout.png" />
                                    </a>
                                </div>
                            </div>
                            <div className="ct_navbar_main">
                                <nav className="navbar navbar-expand-lg ">
                                    <div className="container-fluid">
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsibleNavbar">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                            <ul className="navbar-nav">
                                                <li className="nav-item" onClick={() => onHandleNavbar("")}>
                                                    <a className={isActive == "" ? "nav-link active" : "nav-link"} style={cursor} >Dashboard</a>
                                                </li>
                                                <li className="nav-item" onClick={() => onHandleNavbar('rides')}>
                                                    <a className={isActive == "rides" ? "nav-link active" : "nav-link"} style={cursor}>Rides</a>
                                                </li>
                                                <li className="nav-item" onClick={() => onHandleNavbar('cabs')}>
                                                    <a className={isActive == "cabs" ? "nav-link active" : "nav-link"} style={cursor}>Drivers</a>
                                                </li>
                                                <li className="nav-item" onClick={() => onHandleNavbar('users')}>
                                                    <a className={isActive == "users" ? "nav-link active" : "nav-link"} style={cursor}>Users</a>
                                                </li>
                                                <li  className="nav-item" onClick={() => onHandleNavbar('location')}>
                                                    <a className={isActive == "location" ? "nav-link active" : "nav-link"} style={cursor}>Location</a>
                                                </li>
                                                <li  className="nav-item" onClick={() => onHandleNavbar('category')}>
                                                    <a className={isActive == "category" ? "nav-link active" : "nav-link"} style={cursor}>Category</a>
                                                </li>
                                                <li className="nav-item" onClick={() => onHandleNavbar('settings')}>
                                                    <a className={isActive == "settings" ? "nav-link active" : "nav-link"} style={cursor}>Settings</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Navbar
