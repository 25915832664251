import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { message, message as MESSAGE } from "antd";

export const configJSON = require("../Config");

const EditUser = () => {
    const navigate = useNavigate();
    const [user_name, setUser_name] = useState();
    const [phone_number, setPhone_number] = useState();
    const [user_image, setUserImage] = useState();
    const [change_image, setChangeImg] = useState();
    const [show_image, setShowImage] = useState();
    const [isLoader,setIsLoader] = useState(false);


    useEffect(() => {
        getUserData()
        const img = JSON.parse(localStorage.getItem("user_image"));
        setUserImage(img);
    }, []);

    const getUserData = () => {
        const token = JSON.parse(localStorage.getItem("token"));
        setIsLoader(true)
        axios({
            method: "post",
            url: configJSON?.webBaseUrl + configJSON.webGetUserDataEndPointURL,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log({ res })
                setUser_name(res?.data?.user_info[0]?.name);
                setPhone_number(res?.data?.user_info[0]?.phone_no);
            })
            .catch((err) => {
                console.log(err)
            }).finally(()=>setIsLoader(false))
    }

    const onHandleChangevalue = () => {
        const token = JSON.parse(localStorage.getItem("token"));
        setIsLoader(true)
        // const data = {
        //     name: user_name
        // }
        let formData = new FormData();
        formData.append('name', user_name);
        if (change_image) {
            formData.append('file', change_image);
        }
        axios({
            method: "post",
            url: configJSON?.webBaseUrl + configJSON.webEditprofileEndPointURL,
            data: formData,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                if (res?.data?.success == true) {
                    MESSAGE.success(res?.data?.message);
                    getUserData();
                    navigate("/user_profile");
                } else {
                    MESSAGE.error(res?.data?.message);
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(()=>setIsLoader(false))
    }

    const uploadImage = (e) => {
        setChangeImg(e.target.files[0])
        const blob = new Blob([e.target.files[0]], { type: e.target.files[0]?.type });
        setShowImage(URL.createObjectURL(blob))
    }

    return (
        <div>
            <header>
                <div class="ct_navigation">
                    <a onClick={() => navigate('/all_booking')} class="ct_logo">
                        <img src="img/logo.png" alt="img" />
                    </a>
                    <div className="ct_profile_drop" onClick={() => navigate('/user_profile')}>
                        <img src={user_image != 0 ? user_image : "img/user1.png"} alt="" className="ct_profile_logo" />
                    </div>
                </div>
            </header>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 mx-auto">
                            <div class="ct_reutn_booking_main ct_center_positioin w-50">
                                <h4>Profile</h4>
                                <div class="ct_profile_cnt">
                                    <label id="file-input"> <img src={show_image ? show_image : user_image != 0 ? user_image : "img/user1.png"} alt="" />
                                        <input type="file" id=" file-input" className="d-none" onChange={(e) => uploadImage(e)} /></label>
                                    {/* <i class="fa-solid fa-pencil"></i> */}
                                </div>
                                <div class="form-group ct_input_div mb-3">
                                    <input type="text" value={user_name} onChange={(e) => setUser_name(e.target.value)} />
                                </div>
                                <div class="form-group ct_input_div mb-3">
                                    <input type="text" value={phone_number} readOnly />
                                </div>

                                <div class="mt-5">
                                    <button class="ct_custom_btn" onClick={onHandleChangevalue}>Save</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default EditUser
