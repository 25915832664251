import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import Ellipse_1_png from "../Images/Ellipse_1.png";
import Navbar from "./Navbar";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { message, message as MESSAGE } from "antd";
import SendNotification from "./Button/SendNotification";
import CarLoader from "./carLoader";
import NoRecord from "./Com/NoRecord";
export const configJSON = require("../Components/Config");

const Cabs = () => {
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [isNotificationModal, setIsNotificationModal] = useState(false);
  const [description, setDescription] = useState("");
  const [shares, setShares] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [driverDetails, setDriverDetails] = useState([]);
  const [status, setStatus] = useState();
  const [showRides, setShowRides] = useState();
  const [filterData, setFilterData] = useState("all");
  const [active_cabs, setActive_cabs] = useState();
  const [searchData, setSearchData] = useState([]);
  const [searchDataByUser, setSearchDataByUser] = useState();
  const [update_status, setUpdate_status] = useState("all");
  const [isQrShow, setIsQrshow] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      navigate("/login");
    } else {
      getAllDrivers();
    }
  }, []);

  const getAllDrivers = () => {
    setIsLoader(true)
    axios({
      url: configJSON.baseUrl + configJSON.get_all_driver,
      method: "get",
    })
      .then((res) => {
        setDrivers(res?.data?.data);
        setActive_cabs(res?.data?.data[0]?.id);
        if (res?.data?.data[0].id) {
          getDriverDetails(res?.data?.data[0]?.id);
        }
        if (update_status != "") {
          const dummyData = [];
          if (update_status != "all") {
            res?.data?.data?.map(
              (item) => item?.active == update_status && dummyData.push(item)
            );
            setSearchData(dummyData);
            getDriverDetails(dummyData[0]?.id);
            setActive_cabs(dummyData[0]?.id);
          } else {
            const newDummyData = [];
            res?.data?.data?.map((item) => newDummyData.push(item));
            setSearchData(newDummyData);
            getDriverDetails(newDummyData[0]?.id);
            setActive_cabs(newDummyData[0]?.id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };

  const sendNotification = () => {
    if (!description) {
      return message.error("Description is required");
    } else if (description.length > 25) {
      return message.error("Description should be 0 to 25 characters");
    }

    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.send_particular_driver,
      method: "post",
      data: {
        body: description,
        driver_id: id,
      },
    })
      .then((res) => {
        if (res?.data?.success) {
          setIsNotificationModal(false);
          setDescription("");
          setId();
          getAllDrivers();
          message.success(res.data?.message);
        } else {
          message.error(res.data?.message);
        }
      })
      .catch((err) => {
        message.error("Failed to send notification");
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  const getDriverDetails = (id) => {
    setIsLoader(true);
    setActive_cabs(id);
    const data = {
      user_id: `${id}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.get_driver_details,
      method: "post",
      data: data,
    })
      .then((res) => {
        setTimeout(() => {
          setIsLoader(false);
        }, 500);
        setDriverDetails(res?.data?.data[0]);
        setShares(res?.data?.data[0]?.shares);
        console.log(res, "res");
        if (res?.data?.data[0]?.active == "0") setStatus("Active");
        else if (res?.data?.data[0]?.active == "1") setStatus("Inactive");
        else if (res?.data?.data[0]?.active == "2") setStatus("Suspended");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };

  const UpdateDetails = (id) => {
    var isActive;
    if (status == "Active") isActive = 0;
    else if (status == "Inactive") isActive = 1;
    else if (status == "Suspended") isActive = 2;
    const data = {
      user_id: `${id}`,
      is_active: `${isActive}`,
      shares: shares == "" ? 0 : shares,
    };

    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.update_driver_detail,
      method: "post",
      data: data,
    })
      .then((res) => {
        setIsLoader(false);
        getAllDrivers();
        if (res.data.success == false) MESSAGE.error(res?.data?.message);
        else {
          filterUserData(`${update_status}`);
          MESSAGE.success(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };

  const filterUserData = (val) => {
    setUpdate_status(val);
    setFilterData(val);
    const dummyData = [];
    if (val != "all") {
      drivers?.map((item) => item?.active == val && dummyData.push(item));
      setSearchData(dummyData);
      getDriverDetails(dummyData[0]?.id);
      setActive_cabs(dummyData[0]?.id);
    } else {
      const newDummyData = [];

      drivers?.map((item) => newDummyData.push(item));
      setSearchData(newDummyData);
      getDriverDetails(newDummyData[0]?.id);
      setActive_cabs(newDummyData[0]?.id);
    }
  };

  const onHandaleSearchvalue = (e) => {
    setSearchData([]);
    setFilterData("all");
    setSearchDataByUser(e.target.value);
    const data = drivers?.filter(
      (item) =>
        item?.name.toLowerCase().includes(e.target.value.toLowerCase()) &&
        searchData?.push(item)
    );
    setSearchData(data);
    getDriverDetails(searchData[0]?.id);
    setDrivers(searchData);
    setActive_cabs(searchData[0]?.id);
    setSearchData([]);
    if (e.target.value == "") getAllDrivers();
  };
  const showResponse = () => {
    alert("Active");
  };

  const downloadImage = (url) => {
    MESSAGE.success("QR download sucessfully");

    saveAs(`${url}`, "image.jpg"); // Put your image URL here.
  };

  return (
    <div>
      <section className="ct_padd_in_100">
        <Navbar data="cabs" />
        <div className="container-fluid ct_margin_top">
          <div className="row">
            <div className="col-md-6">
              <div className="ct_search_bar">
                <input
                  type="text"
                  className="form-control"
                  value={searchDataByUser}
                  onChange={(e) => onHandaleSearchvalue(e)}
                  placeholder="Search"
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <div className="ct_choose_rides_dot">
                <ul>
                  <li onClick={() => filterUserData("all")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "all" ? true : false}
                      name="radio-group"
                      id="radio1"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio1">
                      <span className="ct_radio-inner-circle"></span>
                      All Rides
                    </label>
                  </li>
                  <li onClick={() => filterUserData("0")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "0" ? true : false}
                      name="radio-group"
                      id="radio2"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio2">
                      <span className="ct_radio-inner-circle"></span>
                      Active
                    </label>
                  </li>
                  <li onClick={() => filterUserData("1")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "1" ? true : false}
                      name="radio-group"
                      id="radio3"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio3">
                      <span
                        className="ct_radio-inner-circle"
                        value={showRides}
                        onChange={() => setShowRides("1")}
                      ></span>
                      Inactive
                    </label>
                  </li>
                  <li onClick={() => filterUserData("2")}>
                    <input
                      className="ct_radio-input"
                      checked={filterData == "2" ? true : false}
                      name="radio-group"
                      id="radio4"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio4">
                      <span className="ct_radio-inner-circle"></span>
                      Suspended
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!isLoader && (
            <>
              <div className="row mt-2">
                <div className="col-lg-8 mb-4">
                  <div className="ct_active_rides_cnt">
                    <h4>Drivers</h4>

                    <div className="ct_activre_rides_all_boxes">
                      {filterData !== "all"
                        ? drivers?.map(
                            (obj) =>
                              obj?.active == filterData && (
                                <div
                                  className={
                                    active_cabs == obj?.id
                                      ? "ct_active_ride_card ct_card_active"
                                      : "ct_active_ride_card"
                                  }
                                  onClick={() => getDriverDetails(obj?.id)}
                                >
                                  <div className="d-flex align-items-center flex-wrap gap-3 gap-md-5">
                                    <div className="d-flex align-items-center flex-wrap gap-3">
                                      {obj?.profile_image != "" &&
                                      obj?.profile_image != "0" ? (
                                        <div className="ct_user_img_circle">
                                          <img src={obj?.profile_image} />
                                        </div>
                                      ) : (
                                        <div className="ct_user_img_circle">
                                          <img src={Ellipse_1_png} />
                                        </div>
                                      )}
                                      <div className="d-flex align-items-center gap-4">
                                        <div className="ct_user_info_name">
                                          <h5>{obj?.name}</h5>
                                          <small>
                                            {obj?.car_model}
                                            {obj?.car_model && <br />}
                                            <strong className="text-dark">
                                              {obj?.phone_no &&
                                              obj.phone_no !== "undefined"
                                                ? obj.phone_no
                                                : ""}
                                            </strong>
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ct_rides_date">
                                      <span>
                                        {moment(obj?.created_at).format(
                                          "MMM DDs YYYY"
                                        )}
                                      </span>
                                      <span>
                                        {moment(obj?.created_at).format(
                                          "h:mm A"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center gap-2 flex-wrap">
                                    <SendNotification
                                      onClick={() => {
                                        setId(obj?.id);
                                        setIsNotificationModal(true);
                                      }}
                                    />
                                    <div className="ct_active_link">
                                      {obj?.active == 0 && (
                                        <a className="ct_green_text">Active</a>
                                      )}
                                      {obj?.active == 1 && (
                                        <a className="ct_green_text">
                                          Inactive
                                        </a>
                                      )}
                                      {obj?.active == 2 && (
                                        <a className="ct_green_text">
                                          Suspended
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                          )
                        : drivers?.map((obj) => (
                            <>
                              <div
                                className={
                                  active_cabs == obj?.id
                                    ? "ct_active_ride_card ct_card_active"
                                    : "ct_active_ride_card"
                                }
                              >
                                <div
                                  className="d-flex align-items-center flex-wrap gap-3 gap-md-5"
                                  onClick={() => getDriverDetails(obj?.id)}
                                >
                                  <div className="d-flex align-items-center flex-wrap gap-3">
                                    {obj?.profile_image != "" &&
                                    obj?.profile_image != "0" ? (
                                      <div className="ct_user_img_circle">
                                        <img
                                          src={obj?.profile_image}
                                          alt="img/Ellipse_1.png"
                                        />
                                      </div>
                                    ) : (
                                      <div className="ct_user_img_circle">
                                        <img
                                          src={Ellipse_1_png}
                                          alt="img/Ellipse_1.png"
                                        />
                                      </div>
                                    )}
                                    <div className="d-flex align-items-center gap-4">
                                      <div className="ct_user_info_name">
                                        <h5>{obj?.name}</h5>
                                        <small>
                                          {obj?.car_model}
                                          {obj?.car_model && <br />}
                                          <strong className="text-dark">
                                            {obj?.phone_no &&
                                            obj.phone_no !== "undefined"
                                              ? obj.phone_no
                                              : ""}
                                          </strong>
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ct_rides_date">
                                    <span>
                                      {moment(obj?.created_at).format(
                                        "MMM Do YYYY"
                                      )}
                                    </span>
                                    <span>
                                      {moment(obj?.created_at).format("h:mm A")}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-item-center gap-2 flex-wrap justify-content-center align-items-center">
                                  <SendNotification
                                    onClick={() => {
                                      setId(obj?.id);
                                      setIsNotificationModal(true);
                                    }}
                                  />
                                  <div className="ct_active_link">
                                    {obj?.active == 0 && (
                                      <a className="ct_green_text">Active</a>
                                    )}
                                    {obj?.active == 1 && (
                                      <a className="ct_green_text">Inactive</a>
                                    )}
                                    {obj?.active == 2 && (
                                      <a className="ct_green_text">Suspended</a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className="ct_active_rides_cnt"></div>
                  {isLoader == true && (
                    <div className="loader">
                      <svg
                        className="car"
                        width="102"
                        height="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          transform="translate(2 1)"
                          stroke="#002742"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            className="car__body"
                            d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                            stroke-width="3"
                          />
                          <ellipse
                            className="car__wheel--left"
                            stroke-width="3.2"
                            fill="#FFF"
                            cx="83.493"
                            cy="30.25"
                            rx="6.922"
                            ry="6.808"
                          />
                          <ellipse
                            className="car__wheel--right"
                            stroke-width="3.2"
                            fill="#FFF"
                            cx="46.511"
                            cy="30.25"
                            rx="6.922"
                            ry="6.808"
                          />
                          <path
                            className="car__line car__line--top"
                            d="M22.5 16.5H2.475"
                            stroke-width="3"
                          />
                          <path
                            className="car__line car__line--middle"
                            d="M20.5 23.5H.4755"
                            stroke-width="3"
                          />
                          <path
                            className="car__line car__line--bottom"
                            d="M25.5 9.5h-19"
                            stroke-width="3"
                          />
                        </g>
                      </svg>
                    </div>
                  )}
                  {isLoader == false &&
                  filterData == "all" &&
                  drivers?.length != 0 ? (
                    <div className="ct_rides_detail_box">
                      <span className="ct_grey_text">Driver</span>
                      <div className="d-flex justify-content-between flex-column flex-wrap mt-3 ct_border_btoom pb-3">
                        <div className="d-flex align-items-center flex-wrap gap-3 mb-3 ">
                          <div className="ct_user_img_circle">
                            {driverDetails?.profile_image != "" &&
                            driverDetails?.profile_image != "0" ? (
                              <img src={driverDetails?.profile_image} />
                            ) : (
                              <img
                                src={Ellipse_1_png}
                                alt="img/Ellipse_1.png"
                              />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{driverDetails?.name}</h5>
                              <small>
                                {driverDetails?.car_model}
                                {driverDetails?.car_model && <br />}
                                <strong className="text-dark">
                                  {driverDetails?.phone_no &&
                                  driverDetails.phone_no !== "undefined"
                                    ? driverDetails.phone_no
                                    : ""}
                                </strong>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option>Active</option>
                            <option>Inactive</option>
                            <option>Suspended</option>
                          </select>
                        </div>
                        <div className="mt-3 w-100">
                          <div className="form-group mb-4">
                            <label className="mb-2">Shares</label>
                            <input
                              type="number"
                              value={shares}
                              onChange={(e) => setShares(e.target.value)}
                              readOnly={isQrShow ? true : false}
                              placeholder="Share"
                              className="form-control"
                            />
                          </div>
                          {isQrShow == false && (
                            <button
                              className="ct_custome_btn py-2 ct_btn_border_10  show"
                              onClick={() => setIsQrshow(!isQrShow)}
                            >
                              View QR Code
                            </button>
                          )}
                          {isQrShow == true && (
                            <button
                              className="ct_custome_btn py-2 ct_btn_border_10"
                              onClick={() => setIsQrshow(!isQrShow)}
                            >
                              View Cab Details
                            </button>
                          )}
                        </div>
                      </div>
                      {isQrShow == false && (
                        <div className="mt-4 pt-2  ct_cab_images_w_60 w-100">
                          <h4>Cab Photos</h4>
                          <div className="ct_cab_images ">
                            <img
                              className="w-100"
                              src={
                                driverDetails?.car_image
                                  ? driverDetails?.car_image
                                  : "./img/defaultTaxi.jpg"
                              }
                              alt="Car Image"
                            />
                            {/* <img className="w-100" src={cab_2_png} alt="img/cab_2.png" />
                                            <img className="w-100" src={cab_3_png} alt="img/cab_3.png" /> */}
                          </div>
                        </div>
                      )}
                      {isQrShow == true && (
                        <div className="mt-4 pt-2  ct_cab_images_w_60 w-100">
                          <h4>Cab Photos</h4>
                          <div className="ct_cab_images ">
                            <img
                              className="w-100"
                              src={driverDetails?.QR_code_image}
                              style={{ objectFit: "contain" }}
                              alt="img/cab_1.png"
                            />
                          </div>
                        </div>
                      )}

                      <div className="mt-4 pt-3">
                        {isQrShow == false && (
                          <button
                            className="ct_custome_btn py-2 ct_btn_border_10"
                            onClick={() => UpdateDetails(driverDetails?.id)}
                          >
                            Update Details
                          </button>
                        )}
                        {isQrShow == true && (
                          <button
                            className="ct_custome_btn py-2 ct_btn_border_10"
                            onClick={() =>
                              downloadImage(driverDetails?.QR_code_image)
                            }
                          >
                            Download QR
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    filterData == driverDetails?.active &&
                    drivers?.length != 0 && (
                      <div className="ct_rides_detail_box">
                        <span className="ct_grey_text">Driver</span>
                        <div className="d-flex justify-content-between flex-wrap gap-3 mt-3 ct_border_btoom pb-3">
                          <div className="d-flex align-items-center flex-wrap gap-3 ">
                            <div className="ct_user_img_circle">
                              {driverDetails?.profile_image != "" &&
                              driverDetails?.profile_image != "0" ? (
                                <img src={driverDetails?.profile_image} />
                              ) : (
                                <img
                                  src={Ellipse_1_png}
                                  alt="img/Ellipse_1.png"
                                />
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <div className="ct_user_info_name">
                                <h5>{driverDetails?.name}</h5>
                                <small>
                                  {driverDetails?.car_model}
                                  {driverDetails?.name && <br />}
                                  <strong className="text-dark">
                                    {driverDetails?.phone_no &&
                                    driverDetails.phone_no !== "undefined"
                                      ? driverDetails.phone_no
                                      : ""}
                                  </strong>
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="ct_active_link w-100">
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option>Active</option>
                              <option>Inactive</option>
                              <option>Suspended</option>
                            </select>
                          </div>
                          <div className="form-group mb-0 w-100">
                            <label className="mb-2">Shares</label>
                            <input
                              type="number"
                              value={shares}
                              onChange={(e) => setShares(e.target.value)}
                              readOnly={isQrShow ? true : false}
                              placeholder="Share"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="mt-4 pt-3 w-100">
                          {isQrShow == false && (
                            <button
                              className="ct_custome_btn py-2 ct_btn_border_10 unshow"
                              onClick={() => setIsQrshow(!isQrShow)}
                            >
                              View QR Code
                            </button>
                          )}
                          {isQrShow == true && (
                            <button
                              className="ct_custome_btn py-2 ct_btn_border_10"
                              onClick={() => setIsQrshow(!isQrShow)}
                            >
                              View Cab Details
                            </button>
                          )}
                        </div>
                        {isQrShow == false && (
                          <div className="mt-4 pt-2  ct_cab_images_w_60 w-100">
                            <h4>Cab Photos</h4>
                            <div className="ct_cab_images ">
                              <img
                                className="w-100"
                                src={
                                  driverDetails?.car_image ||
                                  "./img/defaultTaxi.jpg"
                                }
                                alt="car img"
                              />
                              {/* <img className="w-100" src={cab_2_png} alt="img/cab_2.png" />
                                            <img className="w-100" src={cab_3_png} alt="img/cab_3.png" /> */}
                            </div>
                          </div>
                        )}
                        {isQrShow == true && (
                          <div className="mt-4 pt-2  ct_cab_images_w_60 w-100">
                            <h4>Cab Photos</h4>
                            <div className="ct_cab_images ">
                              <img
                                className="w-100"
                                src={driverDetails?.QR_code_image}
                                style={{ objectFit: "contain" }}
                                alt="img/cab_1.png"
                              />
                            </div>
                          </div>
                        )}
                        <div className="mt-4 pt-3">
                          {isQrShow == false && (
                            <button
                              className="ct_custome_btn py-2 ct_btn_border_10"
                              onClick={() => UpdateDetails(driverDetails?.id)}
                            >
                              Update Details
                            </button>
                          )}
                          {isQrShow == true && (
                            <button
                              className="ct_custome_btn py-2 ct_btn_border_10"
                              onClick={() =>
                                downloadImage(driverDetails?.QR_code_image)
                              }
                            >
                              Download QR
                            </button>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {isLoader && <CarLoader />}
              { searchData?.length == 0 && filterData !== "all" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                 < NoRecord lod={isLoader}/>
                </div>
              ) : (
                drivers?.length == 0 &&
                filterData == "all" && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                   < NoRecord lod={isLoader}/>
                  </div>
                )
              )}
            </>
          )}
         
        </div>
      </section>

      <div
        class={`modal fade ${isNotificationModal ? "ct_modal_show" : ""}`}
        id="ct_add_location"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                class="btn-close"
                onClick={() => {
                  setDescription("");
                  setId();
                  setIsNotificationModal(false);
                }}
              ></button>
            </div>
            <div class="modal-body ct_modal_scroll_12">
              <div className="ct_edit_location_main">
                <h4 className="text-center mb-4">Notification Detail</h4>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Description
                        </label>
                        <input
                          type="text"
                          placeholder="Please enter description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="button"
                      class="ct_custome_btn py-2 ct_btn_border_10 w-auto mt-4 mx-auto"
                      onClick={sendNotification}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cabs;
