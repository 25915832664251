import React, { useEffect, useState } from "react";
import Texi_card_icon_png from "../Images/Texi_card_icon.png";
import All_users_png from "../Images/All_users.png";
import all_taxi_png from "../Images/all_taxi.png";
import new_user_png from "../Images/new_user.png";
import Ellipse_1_png from "../Images/Ellipse_1.png";
import from_to_png from "../Images/from_to.png";
import from_to_red_png from "../Images/from_to_red.png";
import axios from "axios";
import girl_png_png from "../Images/girl_png.png";
import Navbar from "./Navbar";
import { message as MESSAGE } from "antd";
import { useNavigate } from "react-router-dom";
import NoRecord from "./Com/NoRecord";
export const configJSON = require("../Components/Config");
const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [activeRides, setActiveRides] = useState();
  const [allUsers, setAllUsers] = useState();
  const [allCabs, setAllCabs] = useState();
  const [rides, setRides] = useState([]);
  const [bookedride, setBookedRide] = useState([]);
  const [data, setData] = useState([]);
  const [active_dashboard, setActive_dashboard] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      navigate("/login");
    } else {
      activeRidesCount();
      usersCount();
      cabsCount();
      getAllRides();
      allBookedCabs();
    }
  }, []);

  const activeRidesCount = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.active_rides,
      method: "get",
    })
      .then((res) => {
        setActiveRides(res.data.data[0].active_rides);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };
  
  const usersCount = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.users_count,
      method: "get",
    })
      .then((res) => {
        setAllUsers(res.data.data[0].all_users);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };
  const cabsCount = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.all_cabs_count,
      method: "get",
    })
      .then((res) => {
        setAllCabs(res.data.data[0].total_cabs);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  const getAllRides = (val) => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.all_active_rides,
      method: "get",
    })
      .then((res) => {
        for (var i = 0; i < res?.data?.data?.length; i++) {
          if (res?.data?.data[i]?.Ride_status != 2) {
            rides?.push(res?.data?.data[i]);
          }
        }
        setRides((rides) => rides?.filter((item) => item));
        setActive_dashboard(res?.data?.data[0]?.id);
        if (val) {
          ActiveUserId(val);
        } else {
          ActiveUserId(res?.data?.data[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };
  const allBookedCabs = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.all_booked_cabs,
      method: "get",
    })
      .then((res) => {
        setBookedRide(res.data.data[0].booked_cabs);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  const ActiveUserId = (id) => {
    setIsLoader(true);
    setActive_dashboard(id);
    const data = {
      user_id: `${id}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.get_active_user_id,
      method: "post",
      data: data,
    })
      .then((res) => {
        setData(res.data.data[0]);
        if (
          res?.data?.data[0]?.driver_status == 1 &&
          res?.data?.data[0]?.Ride_status != 2
        ) {
          setStatus("status");
        } else if (res?.data?.data[0]?.Ride_status == 2) {
          setStatus("Completed");
        } else if (
          res?.data?.data[0]?.driver_status == 2 &&
          res?.data?.data[0]?.Ride_status != 2
        ) {
          setStatus("Cancelled");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoader(false));
  };

  const UpdateRides = (id) => {
    setIsLoader(true);
    var isActive;
    if (status == "Active") isActive = 1;
    else if (status == "Completed") isActive = 3;
    else if (status == "Cancelled") isActive = 2;
    const data = {
      user_id: `${id}`,
      driver_status: `${isActive}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.status_change,
      method: "post",
      data: data,
    })
      .then((res) => {
        if (res.data.success == false) MESSAGE.error(res?.data?.message);
        else {
          MESSAGE.success(res?.data?.message);
          getAllRides(id);
          activeRidesCount();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div>
      <section className="ct_padd_in_100">
        <Navbar data="" />
        <div className="container-fluid ct_margin_top ">
          {isLoader == true && (
            <div className="loader">
              <svg
                className="car"
                width="102"
                height="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  transform="translate(2 1)"
                  stroke="#002742"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    className="car__body"
                    d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                    stroke-width="3"
                  />
                  <ellipse
                    className="car__wheel--left"
                    stroke-width="3.2"
                    fill="#FFF"
                    cx="83.493"
                    cy="30.25"
                    rx="6.922"
                    ry="6.808"
                  />
                  <ellipse
                    className="car__wheel--right"
                    stroke-width="3.2"
                    fill="#FFF"
                    cx="46.511"
                    cy="30.25"
                    rx="6.922"
                    ry="6.808"
                  />
                  <path
                    className="car__line car__line--top"
                    d="M22.5 16.5H2.475"
                    stroke-width="3"
                  />
                  <path
                    className="car__line car__line--middle"
                    d="M20.5 23.5H.4755"
                    stroke-width="3"
                  />
                  <path
                    className="car__line car__line--bottom"
                    d="M25.5 9.5h-19"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </div>
          )}
          {isLoader == false && (
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="ct_card ct_yellow_head_bg">
                  <div className="d-flex align-items-center gap-2 ct_responsive_block mb-3">
                    <div className="ct_card_icon">
                      <img
                        src={Texi_card_icon_png}
                        alt="img/Texi_card_icon.png"
                      />
                    </div>
                    <div className="ct_card_title">
                      <h5 className="ct_font_20 text-white">All Active Ride</h5>
                    </div>
                  </div>
                  <h2 className="text-white ct_font_35">{activeRides}</h2>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="ct_card ct_yellow_head_bg">
                  <div className="d-flex align-items-center gap-2 ct_responsive_block  mb-3">
                    <div className="ct_card_icon">
                      <img src={All_users_png} alt="img/All_users.png" />
                    </div>
                    <div className="ct_card_title">
                      <h5 className="ct_font_20 text-white">All Users</h5>
                    </div>
                  </div>
                  <h2 className="text-white ct_font_35">{allUsers}</h2>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="ct_card ct_yellow_head_bg">
                  <div className="d-flex align-items-center gap-2 ct_responsive_block  mb-3">
                    <div className="ct_card_icon">
                      <img src={all_taxi_png} alt="img/all_taxi.png" />
                    </div>
                    <div className="ct_card_title">
                      <h5 className="ct_font_20 text-white">All Drivers</h5>
                    </div>
                  </div>
                  <h2 className="text-white ct_font_35">{allCabs}</h2>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="ct_card ct_yellow_head_bg">
                  <div className="d-flex align-items-center gap-2 ct_responsive_block mb-3">
                    <div className="ct_card_icon">
                      <img src={new_user_png} alt="img/new_user.png" />
                    </div>
                    <div className="ct_card_title">
                      <h5 className="ct_font_20 text-white">All Booked Cabs</h5>
                    </div>
                  </div>
                  <h2 className="text-white ct_font_35">{bookedride}</h2>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-2">
            <div className="col-lg-8 mb-4">
              <div className="ct_active_rides_cnt">
                <h4>Current active rides</h4>
                {isLoader == true && (
                  <div className="loader">
                    <svg
                      className="car"
                      width="102"
                      height="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(2 1)"
                        stroke="#002742"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          className="car__body"
                          d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                          stroke-width="3"
                        />
                        <ellipse
                          className="car__wheel--left"
                          stroke-width="3.2"
                          fill="#FFF"
                          cx="83.493"
                          cy="30.25"
                          rx="6.922"
                          ry="6.808"
                        />
                        <ellipse
                          className="car__wheel--right"
                          stroke-width="3.2"
                          fill="#FFF"
                          cx="46.511"
                          cy="30.25"
                          rx="6.922"
                          ry="6.808"
                        />
                        <path
                          className="car__line car__line--top"
                          d="M22.5 16.5H2.475"
                          stroke-width="3"
                        />
                        <path
                          className="car__line car__line--middle"
                          d="M20.5 23.5H.4755"
                          stroke-width="3"
                        />
                        <path
                          className="car__line car__line--bottom"
                          d="M25.5 9.5h-19"
                          stroke-width="3"
                        />
                      </g>
                    </svg>
                  </div>
                )}
                {isLoader == false && (
                  <div className="ct_activre_rides_all_boxes">
                    {rides?.length != 0 &&
                      rides?.map((item) => (
                        <div
                          className={
                            active_dashboard == item?.id
                              ? "ct_active_ride_card ct_card_active"
                              : "ct_active_ride_card"
                          }
                          onClick={() => ActiveUserId(item.id)}
                        >
                          <div className="d-flex align-items-center flex-wrap gap-5">
                            <div className="d-flex align-items-center flex-wrap gap-3">
                              <div className="ct_user_img_circle">
                                {item?.driver_data?.profile_image != "" &&
                                item?.driver_data?.profile_image != "0" ? (
                                  <img src={item?.driver_data?.profile_image} />
                                ) : (
                                  <img src={Ellipse_1_png} />
                                )}
                              </div>
                              <div className="d-flex align-items-center gap-4">
                                <div className="ct_user_info_name">
                                  <h5>{item?.driver_data?.name}</h5>
                                  <small>{item?.driver_data?.car_model}</small>
                                </div>
                              </div>
                            </div>
                            <div className="ct_rides_date">
                              <span>{item?.dateOfBooking}</span>
                              <span>{item?.time}</span>
                            </div>
                            {/* <div className="ct_location_list">
                                                        <ul>
                                                            <li>
                                                                <img src={location_icon_png} alt="img/location_icon.png" />
                                                                <p>4.5 km</p>
                                                            </li>
                                                            <li>
                                                                <img src={clock_icon_png} alt="img/clock_icon.png" />
                                                                <p>4 min</p>
                                                            </li>
                                                            <li>
                                                                <img src={account_icon_png} alt="img/account_icon.png" />
                                                                <p>$7.00</p>
                                                            </li>
                                                        </ul>
                                                    </div> */}
                          </div>
                          <div className="ct_active_link">
                            <a className="ct_yellow_text">Active</a>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            {rides?.length == 0 && (
              <div
                className="pb-5"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <NoRecord lod={isLoader} />
              </div>
            )}
            {isLoader == true && (
              <div className="loader">
                <svg
                  className="car"
                  width="102"
                  height="40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    transform="translate(2 1)"
                    stroke="#002742"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      className="car__body"
                      d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                      stroke-width="3"
                    />
                    <ellipse
                      className="car__wheel--left"
                      stroke-width="3.2"
                      fill="#FFF"
                      cx="83.493"
                      cy="30.25"
                      rx="6.922"
                      ry="6.808"
                    />
                    <ellipse
                      className="car__wheel--right"
                      stroke-width="3.2"
                      fill="#FFF"
                      cx="46.511"
                      cy="30.25"
                      rx="6.922"
                      ry="6.808"
                    />
                    <path
                      className="car__line car__line--top"
                      d="M22.5 16.5H2.475"
                      stroke-width="3"
                    />
                    <path
                      className="car__line car__line--middle"
                      d="M20.5 23.5H.4755"
                      stroke-width="3"
                    />
                    <path
                      className="car__line car__line--bottom"
                      d="M25.5 9.5h-19"
                      stroke-width="3"
                    />
                  </g>
                </svg>
              </div>
            )}
            {isLoader == false && rides?.length != 0 && (
              <div className="col-lg-4 mb-4 ">
                <div className="ct_active_rides_cnt">
                  <h4 className="text-center ">Ride Details</h4>
                </div>

                <div className="ct_rides_detail_box">
                  <span className="ct_grey_text">Driver</span>
                  <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                    <div className="d-flex align-items-center flex-wrap gap-3 ">
                      <div className="ct_user_img_circle">
                        {data?.driver_data?.profile_image != "" &&
                        data?.driver_data?.profile_image != "0" ? (
                          <img src={data?.driver_data?.profile_image} />
                        ) : (
                          <img src={Ellipse_1_png} />
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>{data?.driver_data?.name}</h5>
                          <small>
                            {data?.driver_data?.car_model} <br />
                            {data?.driver_data?.phone_no}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="ct_active_link">
                      <a href="#" className="ct_yellow_text">
                        Active
                      </a>
                    </div>
                  </div>

                  <div className="ct_border_btoom  py-3">
                    {/* <div className="ct_location_list ">
                                        <ul className="justify-content-between w-100 ct_mobile_wrap_list">
                                            <li>
                                                <img src={location_icon_png} alt="img/location_icon.png" />
                                                <p>4.5 km</p>
                                            </li>
                                            <li>
                                                <img src={clock_icon_png} alt="img/clock_icon.png" />
                                                <p>4 min</p>
                                            </li>
                                            <li>
                                                <img src={account_icon_png} alt="img/account_icon.png" />
                                                <p>$7.00</p>
                                            </li>
                                        </ul>
                                    </div> */}
                    <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                      <p className="mb-0 ct_grey_text">Date & Time</p>
                      <p>
                        <span className="mb-0">{data?.dateOfBooking}</span>
                        &nbsp;&nbsp;<span className="mb-0">{data?.time}</span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap mt-3  ">
                    <div className="d-flex align-items-center gap-4">
                      <div className="ct_user_info_name">
                        <h5>Current Location</h5>
                        <small>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          {data.start}
                        </small>
                      </div>
                    </div>

                    <div className="ct_active_link">
                      <img src={from_to_png} alt="img/from_to.png" />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap  ">
                    <div className="d-flex align-items-center gap-4">
                      <div className="ct_user_info_name">
                        <h5>Soft Bank Buildings</h5>
                        <small>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          {data.destination}
                        </small>
                      </div>
                    </div>

                    <div className="ct_active_link">
                      <img src={from_to_red_png} alt="img/from_to_red.png" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <span className="ct_grey_text mb-3 d-block">User</span>
                    <div className="d-flex align-items-center flex-wrap gap-3">
                      <div className="ct_user_img_circle">
                        {data?.profile_image != "" &&
                        data?.profile_image != "0" ? (
                          <img
                            className="ct_profile_pic"
                            src={data?.profile_image}
                          />
                        ) : (
                          <img
                            className="ct_profile_pic"
                            src={girl_png_png}
                            alt="img/girl_png.png"
                          />
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>{data?.name}</h5>
                          <small>{data.phone_no}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ct_slect_option_w">
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option>Active</option>
                      <option>Completed</option>
                      <option>Cancelled</option>
                    </select>
                  </div>
                  <div className="mt-4">
                    <button
                      className="ct_custome_btn py-2 ct_btn_border_10"
                      onClick={() => UpdateRides(data.id)}
                    >
                      Update Ride
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Dashboard;
