import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import { Descriptions, message } from "antd";
import axios from "axios";
import CarLoader from "./carLoader";


export const configJSON = require("../Components/Config");
const Location = () => {
  const updateCloseButtonRef = useRef(null);
  const [locationData, setLocationData] = useState([]);
  const [isAddModel, setIsAddModel] = useState(false);
  const [isEditModel, setIsEditModel] = useState(false);
  const [isDescriptionModel, setIsDescriptionModel] = useState(false);
  // Add Model State
  const [locationImage, setLocationImage] = useState();
  const [imageUrl1, setImageUrl1] = useState("");
  const [locationImages, setLocationImages] = useState([]);
  const [price, setPrice] = useState();
  const [category, setCategory] = useState();
  const [categories, setCategories] = useState([]);

  // both models
  const [latLong, setLatLong] = useState({});
  const [location, setLocation] = useState("");
  const [locationName, setLocationName] = useState();
  const [location_name, setLocation_Name] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [isOption, setIsOption] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [editLocationDetail, setEditLocationDetail] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [isEditImage, setIsEditImage] = useState(false);
  const [Modalfade, setIModalfade] = useState(false);

  const [description, setDescription] = useState("");
  const [addDescription, setaddDescription] = useState("");
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [modalDescription, setModalDescription] = useState("");


  useEffect(() => {
    getLocationData();
    getCategories();
  }, []);

  const getCategories = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.categories,
      method: "get",
    })
      .then((res) => {
        if (res?.data?.success == true) {
          setCategories(res?.data?.categories);
          setIsLoader(false);
        } else {
          // message.error(res?.data?.message)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };

  const createCategory = () => {
    if (category) {
      setIsLoader(true);
      axios({
        url: configJSON.baseUrl + configJSON.addCategory,
        method: "post",
        data: { name: category },
      })
        .then((res) => {
          if (res?.data?.success == true) {
            message.success(res?.data?.message);
            getCategories();
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      message.error("Please enter category");
    }
  };

  // get location dropdown
  const getLocations = (val) => {
    setLocationName(val);
    axios({
      url: configJSON.baseUrl + configJSON.getAdress,
      method: "post",
      data: {
        input: val,
      },
    })
      .then((res) => {
        if (res?.data?.success == true) {
          setLocationArray(res?.data?.data);
          setIsOption(true);
          setDescription();
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>setIsLoader(false))
  };
  // Get Latitute & Longitute
  const getLatLong = (val) => {
    axios({
      url: configJSON.baseUrl + configJSON.getLatLong + `?address=${val}`,
      method: "get",
    })
      .then((res) => {
        console.log(res, "tes");
        if (res?.data?.success == true) {
          setLatLong(res?.data?.data);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>setIsLoader(false))
  };
  // Get all locations list
  const getLocationData = () => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.explore,
      method: "get",
    })
      .then((res) => {
        setIsLoader(false);
        if (res?.data?.success == true) {
          setLocationData(res?.data?.explore_locations);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoader(false);
      }).finally(()=>setIsLoader(false))
  };
  // Delete perticular location
  const handleDeleteLocation = (id) => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.explore,
      method: "delete",
      data: {
        id: id,
      },
    })
      .then((res) => {
        setIsLoader(false);
        if (res?.data?.success == true) {
          message.success(res?.data?.message);
          getLocationData();
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoader(false);
      })
      .finally(()=>setIsLoader(false))
  };
  // Get Perticular location detail
  const getLocationDetail = (id) => {
    localStorage.setItem("locationDetailId", JSON.stringify(id));
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.explore + `/${id}`,
      method: "get",
    })
      .then((res) => {
        setIsLoader(false);
        if (res?.data?.success == true) {
          setEditLocationDetail(res?.data?.locationData);
          setCategory(res?.data?.locationData?.categoryName);
          setLocation_Name(res?.data?.locationData?.location_name);
          setLocation(res?.data?.locationData?.location);
          setLocationName(res?.data?.locationData?.location_name);
          setImageUrl(res?.data?.locationData?.avatar_url);
          setLatLong({
            lat: res?.data?.locationData?.latitude,
            lng: res?.data?.locationData?.longitude,
          });
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoader(false);
      })
      .finally(()=>setIsLoader(false))
  };
  // Add Location
  const addLocation = () => {
    const formData = new FormData();
    for (var i = 0; i < locationImages?.length; i++) {
      formData.append("cover", locationImages[i]);
    }
    let status = 1;
    const fields = [
      { key: "avatar", value: locationImage },
      { key: "latitude", value: latLong?.lat },
      { key: "longitude", value: latLong?.lng },
      { key: "price", value: price },
      { key: "location_name", value: location_name },
      { key: "location", value: location },
      { key: "categoryName", value: category },
      { key: "description", value: addDescription || " " },
    ];

    fields.forEach(({ key, value }) => {
      if (value) {
        formData.append(key, value);
      } else {
        console.log({ key, value });
        status = 0;
      }
    });

    if (status === 0) {
      message.error(`Please fill all fields!`);
    } else {
      setIsLoader(true);
      axios({
        url: configJSON.baseUrl + configJSON.explore,
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          setIsLoader(false);
          if (res?.data?.success == true) {
            setIsAddModel(false);
            getLocationData();
            setLocationName("");
            setCategory("");
            setaddDescription("");
            message.success(res?.data?.message);
            handleAddFieldEmpty();
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((err) => {
          handleAddFieldEmpty();
          setIsLoader(false);
        })
        .finally(()=>setIsLoader(false))
    }
  };
  // Update location detail
  const handleSaveChanges = () => {
    const id = JSON.parse(localStorage.getItem("locationDetailId"));
    const formData = new FormData();
    for (var i = 0; i < imageUrls?.length; i++) {
      formData.append("cover", imageUrls[i]);
    }
    formData.append("id", id);

    let status = 1;

    const fields = [
      { key: "avatar", value: editLocationDetail.avatar_url },
      { key: "latitude", value: latLong?.lat },
      { key: "longitude", value: latLong?.lng },
      { key: "price", value: editLocationDetail?.price },
      { key: "location_name", value: location_name },
      { key: "location", value: location },
      { key: "categoryName", value: category },
      { key: "description", value: description || " " },
    ];

    fields.forEach(({ key, value }) => {
      if (value) {
        if (key == "avatar") {
          isEditImage == true && formData.append(key, value);
        } else {
          formData.append(key, value);
        }
      } else {
        status = 0;
      }
    });

    if (status === 0) {
      message.error(`Please fill all fields!`);
    } else {
      setIsLoader(true);
      axios({
        url: configJSON.baseUrl + configJSON.explore,
        method: "patch",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          setIsLoader(false);
          if (res?.data?.success == true) {
            setIsEditModel(false);
            getLocationData();
            handelEditFieldEmpty();
            message.success(res?.data?.message);
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log("an err occured",err);
        })
        .finally(()=>setIsLoader(false))
    }
    console.log(editLocationDetail, "hello");
  };
  // Delete image on update location time
  const handleDeleteImage = (id) => {
    const locationDetailId = JSON.parse(
      localStorage.getItem("locationDetailId")
    );
    axios({
      url: configJSON.baseUrl + configJSON.deleteImage,
      method: "delete",
      data: {
        imageId: id,
      },
    })
      .then((res) => {
        if (res?.data?.success == true) {
          getLocationDetail(locationDetailId);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("An err occured",err);
      })
      .finally(()=>setIsLoader(false))
  };
  // Add model image selection
  const hanldeLocationImage = (e) => {
    setLocationImage(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      setImageUrl1(URL.createObjectURL(e.target.files[0]));
    } else {
      setImageUrl1("");
    }
  };
  const hanldeLocationImages = (e) => {
    const selectedImages = Array.from(e.target.files);
    setLocationImages([...locationImages, ...selectedImages]);
  };
  const handleLocationImagesDelete = (index) => {
    const updatedImages = [...locationImages];
    updatedImages.splice(index, 1);
    setLocationImages(updatedImages);
  };

  // Update model image selection
  const handleImageUrls = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImageUrls([...imageUrls, ...selectedImages]);
  };
  const hanldeEditLocationImage = (e) => {
    setIsEditImage(true);
    const file = e.target.files[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      setEditLocationDetail({ ...editLocationDetail, avatar_url: file });
    } else {
      setImageUrl("");
      setEditLocationDetail({ ...editLocationDetail, avatar_url: null });
    }
  };
  const handleImageUrlsDelete = (index) => {
    const updatedImages = [...imageUrls];
    updatedImages.splice(index, 1);
    setImageUrls(updatedImages);
  };

  // For Empty the fields
  const handleAddFieldEmpty = () => {
    setIModalfade(true);
    setLocationImage("");
    setLocationImages([]);
    setImageUrl1("");
    setLatLong("");
    setPrice("");
    setCategory("");
    setLocation_Name("");
    setLocation("");
  };
  const handelEditFieldEmpty = () => {
    if (updateCloseButtonRef.current) {
      updateCloseButtonRef.current.click();
    }
    setEditLocationDetail("");
    setLatLong("");
    setLocation("");
    setLocation_Name("");
    setLocationName("");
    setImageUrls([]);
  };
  // set location values
  const handleClick = (val) => {
    setIsOption(false);
    setLocationName(val);
    setLocation_Name(val?.split(",")[0]);
    setLocation(val?.split(",")?.slice(-2)?.join(","));
    getLatLong(val);
  };


    // Description modal
  const toggleDescription = (index) => {
    const newExpandedDescriptions = [...expandedDescriptions];
    newExpandedDescriptions[index] = !newExpandedDescriptions[index];
    setExpandedDescriptions(newExpandedDescriptions);
  };

  const showModalWithDescription = (description) => {
    setModalDescription(description);
  };

  return (
    <div>
      <section className="ct_padd_in_100">
        <Navbar data="location" />
        <div className="row mt-5">
          <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-4">
            <h4>Location</h4>
            <button
              class="ct_custome_btn py-2 ct_btn_border_10 w-auto py-2"
              onClick={() => setIsAddModel(true)}
            >
              <i class="fa-solid fa-plus me-1"></i> Add Location
            </button>
          </div>
          <div className="ct_location_table table-responsive">
            {isLoader && <CarLoader />}
            {!isLoader && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Images</th>
                    <th>Location Name</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>State {/*City*/}</th>
                    <th>Country</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {locationData?.length != 0 ? (
                    locationData?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className="ct_location_img_120">
                            {console.log("image url",index,item?.avatar_url)}
                            <img src={item?.avatar_url ? item?.avatar_url : "../Images/login_logo.png"} alt="" />
                          </div>
                        </td>
                        <td>{item?.location_name}</td>
                        {/* Description Field with "Show More/Less" functionality */}  
                                        
                        <td
                          key={index}
                          className="ct_description_text_area"
                        >
                          {item?.description ? (
                            item?.description.length > 10 ? (
                              expandedDescriptions[index] ? (
                                <>
                                  {item?.description}{" "}
                                  <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() => toggleDescription(index)}
                                  >
                                    less
                                  </span>
                                </>
                              ) : (
                                <>
                                  {item?.description.slice(0, 70)}...{" "}
                                  <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() =>
                                      showModalWithDescription(
                                        item?.description
                                      )
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#ct_description_modal"
                                  >
                                    more
                                  </span>
                                </>
                              )
                            ) : (
                              <>{item?.description}</>
                            )
                          ) : (
                            <span></span>
                          )}
                        </td>

                        <td>{item?.categoryName}</td>
                        <td>{item?.location.split(",")[0]}</td>
                        <td>{item?.location.split(",")[1]}</td>
                        <td> $ {item?.price}</td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="ct_edit_tbl_icon"
                              onClick={() => {
                                setIsEditModel(true);
                                getLocationDetail(item?.id);
                                setDescription(item?.description);
                              }}
                            >
                              <i class="fa-solid fa-pen"></i>
                            </button>
                            <button
                              className="ct_dlt_tbl_icon"
                              onClick={() => handleDeleteLocation(item?.id)}
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h3 className="text-center">No data found</h3>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </section>
      {/* Edit Location S */}
      <div
        class={`modal fade ${isEditModel ? "ct_modal_show" : ""}`}
        id="ct_edit_location"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                class="btn-close"
                onClick={() => {
                  setLocationName("");
                  setCategory("");
                  setIsEditModel(false);
                }}
              ></button>
            </div>
            <div class="modal-body ct_modal_scroll_12">
              <div className="ct_edit_location_main">
                <h4 className="text-center mb-4">Edit Location</h4>
                <form>
                  <div class="form-group mb-3">
                    <label htmlFor="" className="mb-2">
                      Upload Location Image
                    </label>
                    <label
                      htmlFor="ct_upload_location1221"
                      className="ct_upload_location_img_1230"
                    >
                      <p className="mb-0">
                        <span>
                          <i class="fa-solid fa-plus me-1"></i>
                        </span>
                        Upload Location Image
                      </p>
                      <input
                        type="file"
                        onChange={(e) => hanldeEditLocationImage(e)}
                        class="form-control d-none"
                        id="ct_upload_location1221"
                      />
                      {editLocationDetail.avatar_url && (
                        <img src={imageUrl} className="" alt="Location" />
                      )}
                    </label>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Location Name
                        </label>
                        <input
                          type="text"
                          value={locationName}
                          onChange={(e) => getLocations(e.target.value)}
                          class="form-control"
                        />
                        <div
                          className={`ct_filter_dropdown ${
                            isOption == true ? "active" : ""
                          }`}
                        >
                          <ul>
                            {locationArray?.map((item) => (
                              <li
                                onClick={() => handleClick(item?.description)}
                              >
                                {item?.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Price
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={editLocationDetail?.price}
                          onChange={(e) =>
                            setEditLocationDetail({
                              ...editLocationDetail,
                              price: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Categories
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setCategory(e.target.value)}
                          value={category}
                        >
                          {categories?.map((cat) => (
                            <option value={cat?.name}>{cat?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6  mb-3">
                      <div className="position-relative ct_add_category12">
                        <div class="form-group ct_flex_1">
                          <label htmlFor="" className="mb-2">
                            Add category
                          </label>
                          <input
                            type="text"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            class="form-control"
                          />
                        </div>
                        <button
                          className="ct_custome_btn"
                          type="button"
                          onClick={() => createCategory()}
                        >
                          Add
                        </button>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group mb-3">
                        <label htmlFor="" className="mb-2">
                          Description
                        </label>
                        <textarea
                          type="textarea"
                          class="form-control"
                          rows={3}
                          style={{ minHeight: "auto", height: "unset" }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label htmlFor="" className="mb-2">
                      Upload Multiple Location Image
                    </label>
                    <div className="d-flex align-items-center gap-2 flex-wrap ct_multiple_img_div_130 mt-3">
                      <label
                        htmlFor="ct_upload_location2"
                        className="ct_upload_location_img_1230 "
                      >
                        <p className="mb-0">
                          <span>
                            <i class="fa-solid fa-plus me-1"></i>
                          </span>
                          Upload
                        </p>
                        <input
                          type="file"
                          class="form-control d-none"
                          multiple={true}
                          onChange={(e) => handleImageUrls(e)}
                          id="ct_upload_location2"
                        />
                      </label>
                      <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center">
                        {editLocationDetail?.locationImages?.map((item) => (
                          <>
                            <div
                              className="position-relative mb-3"
                              style={{ width: " 100px", height: "100px" }}
                            >
                              <img src={item?.image} alt="/location" />
                              <i
                                class="fa-solid fa-xmark ct_dlt_location_img_icon"
                                onClick={() => handleDeleteImage(item?.id)}
                              ></i>
                            </div>
                          </>
                        ))}
                        {imageUrls?.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                            className="position-relative mb-3"
                          >
                            <img
                              src={URL.createObjectURL(item)}
                              alt={`Image ${index + 1}`}
                              style={{ width: "100px", height: "100px" }}
                            />
                            <i
                              class="fa-solid fa-xmark ct_dlt_location_img_icon"
                              onClick={() => handleImageUrlsDelete(index)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      class="ct_custome_btn py-2 ct_btn_border_10 w-auto mt-4 mx-auto"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Location S */}
      {isLoader && <CarLoader />}
      {!isLoader && (
        <div
          class={`modal fade ${isAddModel ? "ct_modal_show" : ""}`}
          id="ct_add_location"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="btn-close"
                  onClick={() => {
                    setLocationName("");
                    setCategory("");
                    setIsAddModel(false);
                    setaddDescription("");
                    setPrice("");
                  }}
                ></button>
              </div>
              <div class="modal-body ct_modal_scroll_12">
                <div className="ct_edit_location_main">
                  <h4 className="text-center mb-4">Add Location</h4>
                  <form>
                    <div class="form-group mb-3">
                      <label htmlFor="" className="mb-2">
                        Upload Location Image
                      </label>
                      <label
                        htmlFor="ct_upload_location"
                        className="ct_upload_location_img_1230"
                      >
                        <p className="mb-0">
                          <span>
                            <i class="fa-solid fa-plus me-1"></i>
                          </span>
                          Upload Location Image
                        </p>
                        <input
                          type="file"
                          class="form-control d-none"
                          onChange={(e) => hanldeLocationImage(e)}
                          id="ct_upload_location"
                        />
                        {imageUrl1 && (
                          <img
                            className="ct_uploaded_img_120"
                            src={imageUrl1}
                          />
                        )}
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div class="form-group mb-3 position-relative">
                          <label htmlFor="" className="mb-2">
                            Location Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={locationName}
                            onChange={(e) => getLocations(e.target.value)}
                          />
                          <div
                            className={`ct_filter_dropdown ${
                              isOption == true ? "active" : ""
                            }`}
                          >
                            <ul>
                              {locationArray?.map((item) => (
                                <li
                                  onClick={() => handleClick(item?.description)}
                                >
                                  {item?.description}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group mb-3">
                          <label htmlFor="" className="mb-2">
                            Price
                          </label>
                          <input
                            type="text"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group mb-3">
                          <label htmlFor="" className="mb-2">
                            Categories
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) => setCategory(e.target.value)}
                            value={category}
                          >
                            {categories?.map((cat) => (
                              <option value={cat?.name}>{cat?.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6  mb-3">
                        <div className="position-relative ct_add_category12">
                          <div class="form-group ct_flex_1">
                            <label htmlFor="" className="mb-2">
                              Add category
                            </label>
                            <input
                              type="text"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                              class="form-control"
                            />
                          </div>
                          <button
                            className="ct_custome_btn"
                            type="button"
                            onClick={() => createCategory()}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-group mb-3">
                          <label htmlFor="" className="mb-2">
                            Description
                          </label>

                          <textarea
                            type="text"
                            class="form-control"
                            rows={3}
                            style={{ minHeight: "auto", height: "unset" }}
                            value={addDescription}
                            onChange={(e) => setaddDescription(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-3">
                      <label htmlFor="" className="mb-2">
                        Upload Multiple Location Image
                      </label>
                      <div className="d-flex align-items-center gap-2 flex-wrap ct_multiple_img_div_130 mt-3">
                        <label
                          htmlFor="ct_multiple_upload_location"
                          className="ct_upload_location_img_1230 "
                        >
                          <p className="mb-0">
                            <span>
                              <i class="fa-solid fa-plus me-1"></i>
                            </span>
                            Upload
                          </p>
                          <input
                            type="file"
                            onChange={(e) => hanldeLocationImages(e)}
                            multiple={true}
                            class="form-control d-none"
                            id="ct_multiple_upload_location"
                          />
                        </label>
                        {locationImages.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                            className="position-relative"
                          >
                            <img
                              src={URL.createObjectURL(item)}
                              alt={`Image ${index + 1}`}
                              style={{ width: "100px", height: "100px" }}
                            />
                            <i
                              class="fa-solid fa-xmark ct_dlt_location_img_icon"
                              onClick={() => handleLocationImagesDelete(index)}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        class="ct_custome_btn py-2 ct_btn_border_10 w-auto mt-4 mx-auto"
                        onClick={addLocation}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {Descriptions Modal} */}
      <div className="modal fade ct_assets_modal" id="ct_description_modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button
                type="button"
                className="btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {/* <i className="fa-solid fa-xmark" /> */}
              </button>
            </div>
            <div className="modal-body border-0">
              <h4 className="text-center mb-4 ct_fw_600">Description</h4>
              <p className="text-center ct_grey_text">{modalDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
