import React, { useState } from 'react'
import qr_cab from "../Images/qr_cab.png"
import login_cab from "../Images/login_cab.png"
import login_logo from "../Images/login_logo.png"
import { useNavigate } from "react-router-dom";
import bg_image from "../Images/bg.png"
import { BsFillEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5"
import { message, message as MESSAGE } from "antd";
import axios from 'axios';
import Loader from "react-js-loader";

export const configJSON = require("../Components/Config");

function ChangePassword() {
    const navigate = useNavigate()
    const [isEye1, setIsEye1] = useState(false)
    const [isEye2, setIsEye2] = useState(false)
    const [type1, setType1] = useState("password")
    const [type2, setType2] = useState("password")
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoader, setIsLoader] = useState(false);

    const onHandleLogin = () => {
        navigate('/login')
    }

    const handleEye1 = () => {
        var chtype = type1 == "text" ? "password" : "text"
        setType1(chtype);
        setIsEye1(!isEye1)
    }

    const handleEye2 = () => {
        var chtype = type2 == "text" ? "password" : "text"
        setType2(chtype);
        setIsEye2(!isEye2)
    }

    const onHanldeChangePassword = (event) => {
        event.preventDefault()
        setIsLoader(true);
        const email = JSON.parse(localStorage.getItem('admin_email'));
        console.log({ email })
        if (newPassword == confirmPassword) {
            const data = {
                email: email,
                password: newPassword
            }
            if (email && newPassword) {
                axios({
                    url: configJSON.baseUrl + configJSON.change_password,
                    method: "post",
                    data: data,
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                })
                    .then((res) => {
                        console.log(res)
                        setIsLoader(false);
                        setNewPassword('')
                        setConfirmPassword('')
                        if (res?.data?.success == true) {
                            MESSAGE.success(res?.data?.message)
                            localStorage.removeItem("admin_email");
                            navigate('/login')
                        }
                        else {
                            MESSAGE.error(res?.data?.message)
                        }
                    })
                    .catch((error) => {
                        setIsLoader(false);
                        console.log(error)
                    })
                    .finally(()=>setIsLoader(false))
            }
            else {
                setIsLoader(false);
                MESSAGE.error(" Please Fill All The Boxes")
            }
        } else {
            MESSAGE.error("NewPassword or ConfirmPassword not matches!")
        }
    }


    return (
        <>
            <section className="ct_login_bg " style={{ "height": "100vh", backgroundImage: `url(${bg_image})` }}>
                <div className="container-fluid">
                    <div className="ct_login_logo">
                        <img src={qr_cab} alt="img/qr_cab.png" />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ct_login_bg_border-clr">
                                <div className="ct_login_innner_cnt">
                                    <div className="ct_cab_img">
                                        <img src={login_cab} alt="" />
                                    </div>
                                    {isLoader == true &&
                                        <div className="loader">
                                            <svg className="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(2 1)" stroke="#002742" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                    <path className="car__body" d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01" stroke-width="3" />
                                                    <ellipse className="car__wheel--left" stroke-width="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808" />
                                                    <ellipse className="car__wheel--right" stroke-width="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808" />
                                                    <path className="car__line car__line--top" d="M22.5 16.5H2.475" stroke-width="3" />
                                                    <path className="car__line car__line--middle" d="M20.5 23.5H.4755" stroke-width="3" />
                                                    <path className="car__line car__line--bottom" d="M25.5 9.5h-19" stroke-width="3" />
                                                </g>
                                            </svg>
                                        </div>
                                    }
                                    {isLoader == false && <div className="ct_login_form">
                                        <form>
                                            <div className="ct_login_head">
                                                <h4>Change Password</h4>
                                                <img src={login_logo} alt="img/login_logo.png" />
                                            </div>

                                            <div className="form-group mb-4" >
                                                <label for="">New Password</label>
                                                <div className="position-relative">
                                                    <input type={type1} className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                    {
                                                        isEye1 == false && <BsFillEyeSlashFill className="ct_hide_eye" onClick={handleEye1} />
                                                    }
                                                    {
                                                        isEye1 && <IoEyeSharp className="ct_hide_eye" onClick={handleEye1} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label for="">Confirm Password</label>
                                                <div className="position-relative">
                                                    <input type={type2} className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                    {
                                                        isEye2 == false && <BsFillEyeSlashFill className="ct_hide_eye" onClick={handleEye2} />
                                                    }
                                                    {
                                                        isEye2 && <IoEyeSharp className="ct_hide_eye" onClick={handleEye2} />
                                                    }
                                                </div>
                                            </div>
                                            <button className="ct_custome_btn mt-4" onClick={(e) => onHanldeChangePassword(e)}>Continue</button>
                                            <div className="ct_log_in_footer">
                                                <ul>
                                                    <li>
                                                        <a>Do you know credential?</a>
                                                    </li>
                                                    <li>
                                                        <span className="ct_dot"></span>
                                                    </li>
                                                    <li>
                                                        <a onClick={onHandleLogin}>Click here to Login</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>}
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default ChangePassword
