import React from "react";

const SendNotification = ({ onClick }) => {
  return (
    <>
      <button
        type="button"
        className="ct_custome_btn py-2 ct_btn_border_10 w-auto ct_fs_14 ct_fw_300"
        onClick={onClick}
      >
        <i class="fa-solid fa-paper-plane me-2"></i> Notification
      </button>
    </>
  );
};

export default SendNotification;
