import React, { useState } from 'react'
import qr_cab from "../Images/qr_cab.png"
import login_cab from "../Images/login_cab.png"
import login_logo from '../Images/login_logo.png'
import bg_image from '../Images/bg.png'
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import { message, message as MESSAGE } from "antd";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5"
import Loader from "react-js-loader";

export const configJSON = require("../Components/Config");

function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [isEye, setIsEye] = useState(false)
    const [type, setType] = useState("password")

    const onHandleSubmit = (event) => {
        event.preventDefault()
        setIsLoader(true);
        const data = {
            email: email,
            password: password,
        }
        if (email && password) {
            axios({
                url: configJSON.baseUrl + configJSON.admin_login,
                method: "post",
                data: data,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
                .then((res) => {
                    setIsLoader(false);
                    if (res?.data?.success == true) {
                        MESSAGE.success(res?.data?.message)
                        setPassword("");
                        setEmail("");
                        localStorage.setItem("token", JSON.stringify(res?.data?.JWT_token))
                        console.log(res?.data?.token, "res?.data?.token")
                        setTimeout(()=>{navigate('/')},400)
                    }
                    else {
                        MESSAGE.error(res?.data?.message)
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    setPassword("")
                    setEmail("")
                })
                .finally(()=>setIsLoader(false))
        }
        else {
            setIsLoader(false);
            MESSAGE.error("Field can not be empty!")
        }
    }

    const handleEye = () => {
        var chtype = type == "text" ? "password" : "text"
        setType(chtype);
        setIsEye(!isEye)
    }
    return (
        <>
            <section className="ct_login_bg" style={{ backgroundImage: `url(${bg_image})` }}>
                <div className="container-fluid">
                    <div className="ct_login_logo">
                        <img src={qr_cab} alt="img/qr_cab.png" />
                    </div>
                    <div className="row">
                        <div className="col-md-12 py-3">
                            <div className="ct_login_bg_border-clr">
                                <div className="ct_login_innner_cnt">
                                    <div className="ct_cab_img">
                                        <img src={login_cab} alt="" />
                                    </div>
                                    {isLoader == true &&
                                        <div className="loader">
                                            <svg className="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(2 1)" stroke="#002742" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                    <path className="car__body" d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01" stroke-width="3" />
                                                    <ellipse className="car__wheel--left" stroke-width="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808" />
                                                    <ellipse className="car__wheel--right" stroke-width="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808" />
                                                    <path className="car__line car__line--top" d="M22.5 16.5H2.475" stroke-width="3" />
                                                    <path className="car__line car__line--middle" d="M20.5 23.5H.4755" stroke-width="3" />
                                                    <path className="car__line car__line--bottom" d="M25.5 9.5h-19" stroke-width="3" />
                                                </g>
                                            </svg>
                                        </div>
                                    }
                                    {isLoader == false && <div className="ct_login_form">
                                        <form action="dashboard.html">
                                            <div className="ct_login_head">
                                                <h4>Admin Log In</h4>
                                                <img src={login_logo} alt="img/login_logo.png" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label for="">Email address</label>
                                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label for="">Password</label>
                                                <div className="position-relative">
                                                    <input type={type} value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" />
                                                    {
                                                        isEye == false && <BsFillEyeSlashFill className="ct_hide_eye" onClick={handleEye} />
                                                    }
                                                    {
                                                        isEye && <IoEyeSharp className="ct_hide_eye" onClick={handleEye} />
                                                    }
                                                </div>
                                            </div>
                                            <button className="ct_custome_btn mt-4" onClick={(event) => onHandleSubmit(event)}  >Log in</button>
                                            <div className="ct_log_in_footer">
                                                <ul>
                                                    <li>
                                                        <a onClick={() => navigate("/forgetpassword")}>Forgot credentials ?</a>
                                                    </li>
                                                    <li>
                                                        <span className="ct_dot"></span>
                                                    </li>
                                                    <li className='ct_pointer_curser'>
                                                        <a onClick={() => navigate("/forgetpassword")} >Click here to change</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
