import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";


export const configJSON = require("../Config");

const UserProfile = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [isLoader,setIsLoader] = useState(false);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        setIsLoader(true)
        axios({
            method: "post",
            url: configJSON?.webBaseUrl + configJSON.webGetUserDataEndPointURL,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log({ res })
                setUserData(res?.data?.user_info);
                localStorage.setItem("user_image", JSON.stringify(res?.data?.user_info[0]?.profile_image))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(()=>setIsLoader(false))
    }, []);

    return (
        <div>
            <header>
                <div className="ct_navigation">
                    <a onClick={() => navigate('/all_booking')} className="ct_logo">
                        <img src="img/logo.png" alt="img" />
                    </a>
                    <div className="ct_profile_drop" onClick={() => navigate('/user_profile')}>
                        <img src={userData[0]?.profile_image != 0 ? userData[0]?.profile_image : "img/user1.png"} alt="" className="ct_profile_logo" />
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="ct_reutn_booking_main ct_center_positioin">
                                <h4>Profile</h4>
                                <div className="ct_profile_cnt">
                                    <img src={userData[0]?.profile_image != 0 ? userData[0]?.profile_image : "img/user1.png"} alt="" />
                                    <h4>{userData[0]?.name}</h4>
                                    <p>{userData[0]?.phone_no}</p>
                                </div>
                                <div className="mt-5">
                                    <a onClick={() => navigate('/edit_profile')} className="ct_custom_btn">Edit</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default UserProfile
