import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { message as MESSAGE } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CTimePicker } from '@coreui/react-pro';
import moment from 'moment';

export const configJSON = require("../Config");

const BookRides = () => {
    const navigate = useNavigate();
    const [bookingDate, setbookingDate] = useState();
    const [bookingTime, setBookingTime] = useState('00:00');
    const [bookingFrom, setBookingFrom] = useState();
    const [mainAreaFrom, setMainAreaFrom] = useState();
    const [mainAreaTo, setMainAreaTo] = useState();
    const [bookingTo, setBookingTo] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [username, setUserName] = useState();
    const [suggestAddress, setSuggestAddress] = useState([]);
    const [suggestAddressTo, setSuggestAddressTo] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];
    const [isLoader,setIsLoader] = useState(false);


    const onDateChange = (e) => {
        setbookingDate(e.target.value);
    }
    const onTimeChange = (e) => {
        if (e.slice(0, 2) > 12) {
            // console.log(e.slice(0, 2))
            // console.log(e, "PM")
            setBookingTime(parseInt((e.slice(0, 2)) - 12) + ":" + e.slice(3, 5) + ' PM');
        }
        else {
            // console.log(e.slice(0, 2), "AM")
            setBookingTime(e.slice(0, 5) + ' AM');
        }
    }

    const searchLocationRequest = data => {
        setBookingFrom(data.target.value);
        setIsLoader(true)
        axios({
            method: 'post',
            url: configJSON.webBaseUrl + configJSON.webGetLocationEndPointURL,
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: {
                val: data.target.value
            }
        })
            .then((res) => {
                console.log(res?.data);
                if (res?.data?.success == false) {
                    setSuggestAddress([])
                } else {
                    setSuggestAddress(res?.data?.response?.predictions);
                }
            })
            .catch(err => {
                console.log('err===>', err);
            }).finally(()=>setIsLoader(false))
    };

    const onHandleBookRide = () => {
        const val = window.location.search;
        const daata = val.split("=")
        if (username && bookingTo && bookingFrom && bookingDate && phoneNumber && bookingTime) {
            // console.log(bookingTime);
            // console.log(moment(bookingDate).format('DD/MM/YYYY'));
            localStorage.setItem("driver_id", JSON.stringify(daata[1]))
            const data = {
                name: username,
                driver_id: daata[1],
                start: mainAreaFrom,
                phone_no: phoneNumber,
                dateOfBooking: moment(bookingDate).format('DD/MM/YYYY'),
                time: bookingTime,
                start_2: bookingFrom,
                destination: mainAreaTo,
                destination_1: bookingTo,
                distance: "50",
                duration: "2hrs",
                fcm_token: "test"
            }
            setIsLoader(true)
            axios({
                method: "Post",
                url: configJSON.webBaseUrl + configJSON.webBookingEndPointURL,
                data: data
            })
                .then((res) => {
                    console.log({ res });
                    if (res?.data?.success == true) {
                        localStorage.setItem("user_details", JSON.stringify(phoneNumber));
                        localStorage.setItem("otp", JSON.stringify(res?.data?.data));
                        navigate('/otp_verification');
                        MESSAGE.success(res?.data?.message);
                    }
                })
                .catch((err) => {
                    console.log({ err });
                })
                .finally(()=>setIsLoader(false))
        } else {
            MESSAGE.error("Please enter all the details!!!");
        }
    }

    const onHandleClickAddress = (val) => {
        setBookingFrom(val?.description);
        setMainAreaFrom(val?.structured_formatting?.secondary_text)
        setSuggestAddress([]);
    }

    const onHandleClickAddressTo = (val) => {
        setBookingTo(val?.description);
        setMainAreaTo(val?.structured_formatting?.secondary_text)
        setSuggestAddressTo([]);
    }

    const searchLocationRequestTo = data => {
        setBookingTo(data.target.value);
        setIsLoader(true)
        axios({
            method: 'post',
            url: configJSON.webBaseUrl + configJSON.webGetLocationEndPointURL,
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: {
                val: data.target.value
            }
        })
            .then((res) => {
                console.log(res?.data?.success == false);
                if (res?.data?.success == false) {
                    setSuggestAddressTo([])
                } else {
                    setSuggestAddressTo(res?.data?.response?.predictions);
                }
            })
            .catch(err => {
                console.log('err===>', err);
            })
            .finally(()=>setIsLoader(false))
    };

    const onhandleChangePhoneNumber = (e) => {
        if (phoneNumber?.length < 10 || phoneNumber?.length == undefined) {
            setPhoneNumber(e.target.value)
        } else {
            if (e.target.value?.length < 10) {
                setPhoneNumber(e.target.value)
            }
        }
    };

    return (
        <div>
            <header>
                <div className="ct_navigation justify-content-center">
                    <div className="ct_logo">
                        <img src="img/logo.png" alt="img" />
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="ct_reutn_booking_main">
                                <h4>Ride Booking</h4>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 mb-4">
                                            <div className="form-group ct_input_div">
                                                <div className="position-relative">
                                                    <DatePicker onFocus={(e) => e.target.readOnly = true} minDate={currentDate} placeholderText="Please select date" selected={bookingDate} onChange={(date) => setbookingDate(date)} />
                                                    <div className="ct_calander_icon1"><i class="fa-regular fa-calendar"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div className="form-group ct_input_div">
                                                <div className="position-relative">
                                                    <CTimePicker ampm={true} time={bookingTime} seconds={false} onTimeChange={(e) => onTimeChange(e)} locale="en-US" />
                                                    <div className="ct_calander_icon1"><i class="fa-regular fa-clock"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="position-relative">
                                                <div className="form-group ct_input_div">
                                                    <input type="text" value={bookingFrom} onChange={(e) => searchLocationRequest(e)} className="form-control" placeholder="From" />
                                                    <img src="img/Vector.png" alt="" className="ct_icon_position" />
                                                </div>
                                                {suggestAddress?.length !== 0 &&
                                                    <div className="ct_filter_srch_div ">
                                                        <ul className="ps-0 mb-0">
                                                            {suggestAddress?.map((item) => (
                                                                <li onClick={() => onHandleClickAddress(item)}>{item?.description}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="position-relative">
                                                <div className="form-group ct_input_div">
                                                    <input type="text" value={bookingTo} onChange={(e) => searchLocationRequestTo(e)} className="form-control" placeholder="To" />
                                                    <img src="img/Vector-red.png" alt="" className="ct_icon_position" />
                                                </div>
                                                {suggestAddressTo?.length !== 0 &&
                                                    <div className="ct_filter_srch_div">
                                                        <ul className="ps-0 mb-0">
                                                            {suggestAddressTo?.map((item) => (
                                                                <li onClick={() => onHandleClickAddressTo(item)}>{item?.description}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="d-flex align-items-center gap-3">
                                                <div value="+1" className="ct_input_div">
                                                    <p className="mb-0 ct_country_num">+1</p>
                                                    {/* <select className="form-control ">
                                                        <option value="">+1</option>
                                                    </select> */}
                                                </div>
                                                <div className="form-group ct_input_div ct_flex_1">
                                                    <input type="number" value={phoneNumber} onChange={(e) => onhandleChangePhoneNumber(e)} className="form-control" placeholder="Phone" />
                                                    <img src="img/call.png" alt="" className="ct_icon_position" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="form-group ct_input_div">
                                                <input type="text" value={username} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="Name" />
                                                <img src="img/user.png" alt="" className="ct_icon_position" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3" onClick={onHandleBookRide}>
                                        <a className="ct_custom_btn">Book Ride</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default BookRides