export const pipfilterBy = (filterValue) => {
  let selectedOption = "";

  switch (filterValue) {
    case "all":
      selectedOption = "all";
      break;
    case "0":
      selectedOption = "Pending";
      break;
    case "1":
      selectedOption = "Accept";
      break;
    case "2":
      selectedOption = "Cancelled";
      break;
    case "3":
      selectedOption = "Completed";
      break;
    case "Scheduled":
      selectedOption = "Scheduled";
      break;
  }
return selectedOption;

  // switch (filterValue) {
  //     case "Scheduled":
  //         return "scheduleStatus"
  //         break;

  //     default:
  //         break;
  // }
};
