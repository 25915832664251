Object.defineProperty(exports, "__esModule", {
  value: true,
});
// local
// export const baseUrl = "http://192.168.29.19:4002/";

export const baseUrl = "https://cabty.net:4001/";
export const webBaseUrl = "https://cabty.net:4001/web_user/";

export const webBookingEndPointURL = "booking";
export const webDriverData = "driver_info";
export const webOtpCheckEndPointURL = "confirmRide";
export const send_notification = "send_notification";
export const webGetAllBookingEndPointURL = "get_AllBookings";
export const webEditprofileEndPointURL = "editProfile_User";
export const webGetLocationEndPointURL = "map_test";
export const webGetUserDataEndPointURL = "user_myProfile";
export const LoginUser = "login/user";
export const forgotPassword = "forgotPassword";
export const get_all_sub_admin = "get_all_sub_admin";
export const update_sub_admin = "update_sub_admin";
export const editProfile = "editProfile";
export const get_active_user_id = "get_active_user_id";
export const active_rides = "active_rides";
export const users_count = "all_users_count";
export const all_cabs_count = "all_cabs_count";
export const all_active_rides = "all_active_rides";
export const all_booked_cabs = "all_booked_cabs";

export const all_rides = "all_rides";
export const assign_ride = "assign_ride";
export const send_particular_driver = "send_particular_driver";
export const ride_details = "ride_details";
export const get_all_driver = "get_all_driver";
export const get_driver_details = "get_driver_details";
export const update_driver_detail = "update_driver_detail";
export const get_user_rides = "get_user_rides";
export const get_user_ride_detail = "get_user_ride_detail";
export const admin_login = "admin_login";
export const forgot_password = "forgot_password";
export const password_change = "password_change";
export const get_all_users = "get_all_users";
export const get_users_detail = "get_users_detail";
export const update_user_detail = "update_user_detail";
export const get_admin_profile = "get_admin_profile";
export const new_update_admin = "new_update_admin";
export const verify_users = "email_verify";
export const change_password = "forgot_password";
export const status_change = "status_change";
export const update_Driver = "update_Driver";

export const explore = "explore";
export const deleteImage = "deleteImage";
export const getAdress = "getAdress";
export const getLatLong = "getLatLong";

// Category
export const categories = "categories";
export const addCategory = "addCategory";

// support
export const supportAPI = "sendSupportMail";


// category
export const editCategoryAPI = "editCategory/";
export const deleteCategoryAPI = "deleteCategory";
