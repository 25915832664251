import React, { useState } from "react";
import { message } from "antd";
import SimpleReactValidator from "simple-react-validator";
import "./support.css";
import axios from "axios";
import { baseUrl, supportAPI } from "../Components/Config";
import CarLoader from "../Components/carLoader";
const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [validator] = useState(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setIsLoading(true);
      axios({
        method: "post",
        url: baseUrl + supportAPI,
        data: formData,
      })
        .then((res) => {
          if (res?.data?.success) {
            message.success(res?.data?.message);
            setFormData({
              name: "",
              email: "",
              comment: "",
            });
            validator.hideMessages();
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((error) => {
          message.error("Something went wrong, please try again later");
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      validator.showMessages();
    }
  };

  return (
    <>
      <header>
        <div className="ct_logo">
          <img src="https://cabty.net:4001/ic_launcher.png" alt="" />
        </div>
      </header>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-4">
              <h4 className="ct_fs_28 ct_fw_600">You can find us here</h4>
              <p>Find help for your queries here:</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-7 mb-4 mb-md-0">
              <div className="ct_left_cnt">
                {isLoading ? (
                  <CarLoader />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <label className="ct_fw_600 mb-2">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control ct_input"
                        placeholder="Enter Name"
                      />
                      {validator.message(
                        "name",
                        formData.name,
                        "required|alpha_space"
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="ct_fw_600 mb-2">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control ct_input"
                        placeholder="Enter Email"
                      />
                      {validator.message(
                        "email",
                        formData.email,
                        "required|email"
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="ct_fw_600 mb-2">Comment</label>
                      <textarea
                        type="text"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        className="form-control ct_input h-auto"
                        rows="4"
                        placeholder="Leave a Comment..."
                      ></textarea>
                      {validator.message(
                        "comment",
                        formData.comment,
                        "required"
                      )}
                    </div>
                    <button className="ct_submit_btn" type="submit">
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-4 col-md-5 mb-md-0">
              <div className="ct_right_cnt">
                <ul>
                  <li>
                    <h4 className="mb-3 ct_fs_20 ct_fw_600">Email Address</h4>
                    <a href="mailto:ccabty@gmail.com">
                      <i className="fa-solid fa-envelope me-2"></i>
                      ccabty@gmail.com
                    </a>
                  </li>
                  <li>
                    <h4 className="mb-3 ct_fs_20 ct_fw_600">Phone Number</h4>
                    <a href="tel:3478251042">
                      <i className="fa-solid fa-phone me-2"></i>3478251042
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Support;
