import React, { useState } from 'react'
import qr_cab from "../Images/qr_cab.png"
import login_logo from "../Images/login_logo.png"
import login_cab from "../Images/login_cab.png"
import { useNavigate } from "react-router-dom";
import bg_image from "../Images/bg.png"
import axios from 'axios'
import { message, message as MESSAGE } from "antd";
import Loader from "react-js-loader";

export const configJSON = require("../Components/Config");

function ForgetPassword() {
    const navigate = useNavigate()
    const onHandleLogin = () => {
        navigate('/login')
    }
    const [email, setEmail] = useState('');
    const [isLoader, setIsLoader] = useState(false);

    const onHandleForget = (event) => {
        event.preventDefault()
        setIsLoader(true);
        const data = {
            email: email,
        }
        if (email) {
            axios({
                url: configJSON.baseUrl + configJSON.verify_users,
                method: "post",
                data: data,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
                .then((res) => {
                    console.log(res)
                    setIsLoader(false);
                    if (res?.data?.success == true) {
                        MESSAGE.success(res?.data?.message)
                        localStorage.setItem('admin_email', JSON.stringify(email));
                        setEmail("")
                        setTimeout(() => {
                            navigate('/changepassword')
                        }, 2000);
                    }
                    else {
                        MESSAGE.error(res?.data?.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setEmail("")
                })
                .finally(()=>setIsLoader(false))
        }
        else {
            setIsLoader(false);
            MESSAGE.error(" Please Fill All The Boxes")
        }
    }

    return (
        <>
            <section className="ct_login_bg " style={{ "height": "100vh", backgroundImage: `url(${bg_image})` }}>
                <div className="container-fluid">
                    <div className="ct_login_logo pb-3">
                        <img src={qr_cab} alt="img/qr_cab.png" />
                    </div>
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="ct_login_bg_border-clr">
                                <div className="ct_login_innner_cnt">
                                    <div className="ct_cab_img">
                                        <img src={login_cab} alt="" />
                                    </div>
                                    {isLoader == true &&
                                        <div className="loader">
                                            <svg className="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(2 1)" stroke="#002742" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                    <path className="car__body" d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01" stroke-width="3" />
                                                    <ellipse className="car__wheel--left" stroke-width="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808" />
                                                    <ellipse className="car__wheel--right" stroke-width="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808" />
                                                    <path className="car__line car__line--top" d="M22.5 16.5H2.475" stroke-width="3" />
                                                    <path className="car__line car__line--middle" d="M20.5 23.5H.4755" stroke-width="3" />
                                                    <path className="car__line car__line--bottom" d="M25.5 9.5h-19" stroke-width="3" />
                                                </g>
                                            </svg>
                                        </div>
                                    }
                                    {isLoader == false && <div className="ct_login_form">
                                        <form >
                                            <div className="ct_login_head">
                                                <h4>Forgot Password?</h4>
                                                <img src={login_logo} alt="img/login_logo.png" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label for="">Email address</label>
                                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" className="form-control" />
                                            </div>

                                            <button className="ct_custome_btn mt-4" onClick={onHandleForget}>Continue</button>
                                            <div className="ct_log_in_footer">
                                                <ul>
                                                    <li>
                                                        <a>Do you know credentials ?</a>
                                                    </li>
                                                    <li>
                                                        <span className="ct_dot"></span>
                                                    </li>
                                                    <li className='ct_pointer_curser'>
                                                        <a onClick={onHandleLogin}>Click here to Login</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgetPassword
