import React, { useEffect, useState } from "react";
import ellipse from "../Images/Ellipse_1.png";
import girl_png from "../Images/girl_png.png";
import from_to from "../Images/from_to.png";
import from_to_red from "../Images/from_to_red.png";
import Navbar from "./Navbar";
import axios from "axios";
import moment from "moment";
import { message, message as MESSAGE } from "antd";
import { useNavigate } from "react-router-dom";
import NoRecord from "./Com/NoRecord";
export const configJSON = require("../Components/Config");

function Rides() {
  const navigate = useNavigate();
  const [AllRides, setAllRides] = useState([]);
  const [riderDetails, setRiderDetails] = useState([]);
  const [filterData, setFilterData] = useState("all");
  const [status, setStatus] = useState();
  const [update_status, setUpdate_status] = useState();
  const [active_ride, setActive_ride] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchDataByUser, setSearchDataByUser] = useState();
  const [modalDriver, setModalDriver] = useState("");
  const [modalData, setModalData] = useState([]);
  const [activeModal, setActive_Modal] = useState();
  const [bookingId, setBookimgId] = useState("");
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      navigate("/login");
    } else {
      getAllRides();
    }
  }, []);

  const getAllRides = (value) => {
    setFilterData("all");
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.all_rides,
      method: "get",
    })
      .then((res) => {
        setAllRides(res?.data?.data);
        setActive_ride(res?.data?.data[0]?.id);
        setIsLoader(false);
        if (update_status != "") {
          const dummyData = [];
          if (update_status != "all") {
            res?.data?.data?.map(
              (item) =>
                item?.driver_status == update_status && dummyData?.push(item)
            );
            setSearchData(dummyData);
            userDetails(dummyData[0]?.id);
            setActive_ride(dummyData[0]?.id);
          } else {
            const newDummyData = [];
            res?.data?.data?.map((item) => newDummyData.push(item));
            setSearchData(newDummyData);
            userDetails(newDummyData[0]?.id);
            setActive_ride(newDummyData[0]?.id);
          }
        }
        if (value) {
          userDetails(value);
        } else {
          userDetails(res?.data?.data[0]?.id);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  const getModalRides = (value, bookid) => {
    setModalDriver(value);
    setBookimgId(bookid);
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.get_all_driver,
      method: "get",
    })
      .then((res) => {
        setModalData(res?.data?.data);
        setActive_Modal(res?.data?.data[0]?.id);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };
  const SendNotification = (userId, driverId) => {
    setIsLoader(true);
    const data = {
      sender_id: `${userId}`,
      reciver_id: `${driverId}`,
      notification_type: "new_ride",
      reciver_type: "Driver",
    };
    axios({
      url: configJSON.baseUrl + configJSON.send_notification,
      method: "post",
      data: data,
    })
      .then((res) => {
        console.log(res, "send notification");
        if (res?.data?.success == true) {
          MESSAGE.success(res?.data?.message);
        } else {
          MESSAGE.error("Notification not send");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoader(false));
  };
  const updateDriver = (val) => {
    setIsLoader(true);
    const data = {
      booking_id: bookingId,
      driver_id: val,
    };
    console.log(data, "update data ");
    axios({
      url: configJSON.baseUrl + configJSON.update_Driver,
      method: "post",
      data: data,
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        console.log(res, "response");
        if (res?.data?.success == true) {
          MESSAGE.success(res?.data?.message);
          setTimeout(() => {
            getAllRides();
            SendNotification(
              res?.data?.booking_info[0]?.user_id,
              res?.data?.booking_info[0]?.driver_id
            );
          }, 1000);
        } else {
          MESSAGE.error(res?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoader(false));
  };

  const userDetails = (id) => {
    setIsLoader(true);
    const data = {
      user_id: `${id}`,
    };
    setActive_ride(id);
    axios({
      url: configJSON.baseUrl + configJSON.ride_details,
      method: "post",
      data: data,
    })
      .then((res) => {
        setIsLoader(false);
        setRiderDetails(res?.data?.data[0]);
        if (res?.data?.data[0]?.driver_status == 1) {
          setStatus("Accept");
        } else if (res?.data?.data[0]?.Ride_status == 2) {
          setStatus("Completed");
        } else if (res?.data?.data[0]?.driver_status == 2) {
          setStatus("Cancelled");
        } else if (res?.data?.data[0]?.driver_status == 0) {
          setStatus("Pending");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  const UpdateRides = (id) => {
    console.log("id :- ", id);
    setIsLoader(true);
    let isActive;
    if (status == "Pending") isActive = 0;
    if (status == "Accept") isActive = 1;
    else if (status == "Completed") isActive = 3;
    else if (status == "Cancelled") isActive = 2;

    const data = {
      user_id: `${id}`,
      driver_status: `${isActive}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.status_change,
      method: "post",
      data: data,
    })
      .then((res) => {
        setTimeout(() => {
          getAllRides();
        }, 1000);
        setIsLoader(false);
        if (res?.data?.success == false) {
          MESSAGE.error(res?.data?.message);
        } else {
          filterUserData(`${update_status}`);

          MESSAGE.success(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  const filterUserData = (val) => {
    setUpdate_status(val);
    setFilterData(val);
    console.log("val");
    const dummyData = [];
    if (val == "3") {
      AllRides?.map(
        (item) => item?.Ride_status == val && dummyData?.push(item)
      );
      setSearchData(dummyData);
      userDetails(dummyData[0]?.id);
      setActive_ride(dummyData[0]?.id);
      console.log({ dummyData });
    } else if (val == "Scheduled") {
      AllRides?.map(
        (item) => item?.scheduledStatus == "Scheduled" && dummyData?.push(item)
      );
      setSearchData(dummyData);
      userDetails(dummyData[0]?.id);
      setActive_ride(dummyData[0]?.id);
      console.log({ dummyData });
    } else if (val != "all") {
      AllRides?.map(
        (item) => item?.driver_status == val && dummyData?.push(item)
      );
      console.log(dummyData, { dummyData });
      setSearchData(dummyData);
      userDetails(dummyData[0]?.id);
      setActive_ride(dummyData[0]?.id);
      console.log({ dummyData });
    } else {
      const newDummyData = [];
      AllRides?.map((item) => newDummyData?.push(item));
      setSearchData(newDummyData);
      userDetails(newDummyData[0]?.id);
      setActive_ride(newDummyData[0]?.id);
    }
  };

  const onHandaleSearchvalue = (e) => {
    setSearchData([]);
    setFilterData("all");
    setSearchDataByUser(e.target.value);
    const data = AllRides?.filter(
      (item) =>
        item?.driver_data?.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) && searchData?.push(item)
    );
    setSearchData(data);
    userDetails(searchData[0]?.id);
    setAllRides(searchData);
    setActive_ride(searchData[0]?.id);
    setSearchData([]);
    if (e.target.value == "") getAllRides();
  };

  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar data="rides" />
        <div className="container-fluid ct_margin_top">
          <div className="row">
            <div className="col-md-6">
              <div className="ct_search_bar">
                <input
                  type="text"
                  className="form-control"
                  value={searchDataByUser}
                  onChange={(e) => onHandaleSearchvalue(e)}
                  placeholder="Search"
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <div className="ct_choose_rides_dot">
                <ul>
                  <li onClick={() => filterUserData("all")}>
                    <input
                      className="ct_radio-input"
                      name="radio-group"
                      checked={filterData == "all" ? true : false}
                      id="radio1"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio1">
                      <span className="ct_radio-inner-circle"></span>
                      All Rides
                    </label>
                  </li>
                  <li onClick={() => filterUserData("1")}>
                    <input
                      className="ct_radio-input"
                      name="radio-group"
                      checked={filterData == "1" ? true : false}
                      id="radio2"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio2">
                      <span className="ct_radio-inner-circle"></span>
                      Accept
                    </label>
                  </li>
                  <li onClick={() => filterUserData("3")}>
                    <input
                      className="ct_radio-input"
                      name="radio-group"
                      checked={filterData == "3" ? true : false}
                      id="radio3"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio3">
                      <span className="ct_radio-inner-circle"></span>
                      Completed
                    </label>
                  </li>
                  <li onClick={() => filterUserData("2")}>
                    <input
                      className="ct_radio-input"
                      name="radio-group"
                      checked={filterData == "2" ? true : false}
                      id="radio4"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio4">
                      <span className="ct_radio-inner-circle"></span>
                      Cancelled
                    </label>
                  </li>
                  <li onClick={() => filterUserData("0")}>
                    <input
                      className="ct_radio-input"
                      name="radio-group"
                      checked={filterData == "0" ? true : false}
                      id="radio5"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio5">
                      <span className="ct_radio-inner-circle"></span>
                      Pending
                    </label>
                  </li>
                  <li onClick={() => filterUserData("Scheduled")}>
                    <input
                      className="ct_radio-input"
                      name="radio-group"
                      checked={filterData == "Scheduled" ? true : false}
                      id="radio6"
                      type="radio"
                    />
                    <label className="ct_radio-label" for="radio6">
                      <span className="ct_radio-inner-circle"></span>
                      Schedule
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-8 mb-4">
              <div className="ct_active_rides_cnt">
                <h4>Rides</h4>
                <div className="ct_activre_rides_all_boxes">
                  {filterData !== "all" && filterData != "Scheduled"
                    ? AllRides?.map(
                        (item) =>
                          item?.driver_status == filterData &&
                          item?.Ride_status != "2" && (
                            <div
                              className={
                                active_ride == item?.id
                                  ? "ct_active_ride_card ct_card_active"
                                  : "ct_active_ride_card"
                              }
                              onClick={() => userDetails(item?.id)}
                            >
                              <div className="d-flex align-items-center flex-wrap gap-5">
                                <div className="d-flex align-items-center flex-wrap gap-3">
                                  <div className="ct_user_img_circle">
                                    {item?.driver_data?.profile_image != "" &&
                                    item?.driver_data?.profile_image != "0" ? (
                                      <img
                                        src={item?.driver_data?.profile_image}
                                      />
                                    ) : (
                                      <img src={ellipse} />
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center gap-4">
                                    <div className="ct_user_info_name">
                                      <h5>{item?.driver_data?.name}</h5>
                                      <small>
                                        {item?.driver_data?.car_model}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="ct_rides_date">
                                  <span className="mb-0">
                                    {item?.dateOfBooking}
                                  </span>
                                  <span className="mb-0">{item?.time}</span>
                                </div>
                              </div>
                              <div className="ct_active_link">
                                {item?.driver_status == 0 &&
                                  item?.Ride_status != 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Pending</a>
                                  )}
                                {item?.driver_status == 1 &&
                                  item?.Ride_status != 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Accept</a>
                                  )}
                                {item?.driver_status == 2 &&
                                  item?.Ride_status != 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Cancelled</a>
                                  )}
                                {item?.Ride_status == 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Completed</a>
                                  )}
                                {item?.scheduledStatus == "Scheduled" && (
                                  <a className="ct_active_link">Scheduled</a>
                                )}
                              </div>
                            </div>
                          )
                      )
                    : filterData !== "all" && filterData != "Scheduled"
                    ? AllRides?.map(
                        (item) =>
                          item?.Ride_status == "2" && (
                            <div
                              className={
                                active_ride == item?.id
                                  ? "ct_active_ride_card ct_card_active"
                                  : "ct_active_ride_card"
                              }
                              onClick={() => userDetails(item?.id)}
                            >
                              <div className="d-flex align-items-center flex-wrap gap-5">
                                <div className="d-flex align-items-center flex-wrap gap-3">
                                  <div className="ct_user_img_circle">
                                    {item?.driver_data?.profile_image != "" &&
                                    item?.driver_data?.profile_image != "0" ? (
                                      <img
                                        src={item?.driver_data?.profile_image}
                                      />
                                    ) : (
                                      <img src={ellipse} />
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center gap-4">
                                    <div className="ct_user_info_name">
                                      <h5>{item?.driver_data?.name}</h5>
                                      <small>
                                        {item?.driver_data?.car_model}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="ct_rides_date">
                                  <span className="mb-0">
                                    {item?.dateOfBooking}
                                  </span>
                                  <span className="mb-0">{item?.time}</span>
                                </div>
                              </div>
                              <div className="ct_active_link">
                                {item?.driver_status == 0 &&
                                  item?.Ride_status != 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Pending</a>
                                  )}
                                {item?.driver_status == 1 &&
                                  item?.Ride_status != 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Accept</a>
                                  )}
                                {item?.driver_status == 2 &&
                                  item?.Ride_status != 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Cancelled</a>
                                  )}
                                {item?.Ride_status == 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Completed</a>
                                  )}
                                {item?.scheduledStatus == "Scheduled" && (
                                  <a className="ct_active_link">Scheduled</a>
                                )}
                              </div>
                            </div>
                          )
                      )
                    : filterData == "Scheduled"
                    ? AllRides?.map(
                        (item) =>
                          item?.scheduledStatus == filterData && (
                            <div
                              className={
                                active_ride == item?.id
                                  ? "ct_active_ride_card ct_card_active"
                                  : "ct_active_ride_card"
                              }
                              onClick={() => userDetails(item?.id)}
                            >
                              <div className="d-flex align-items-center flex-wrap gap-5">
                                <div className="d-flex align-items-center flex-wrap gap-3">
                                  <div className="ct_user_img_circle">
                                    {console.log(
                                      item?.driver_data?.profile_image
                                    )}
                                    {item?.driver_data?.profile_image != "" &&
                                    item?.driver_data?.profile_image != "0" ? (
                                      <img
                                        src={item?.driver_data?.profile_image}
                                      />
                                    ) : (
                                      <img src={ellipse} />
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center gap-4">
                                    <div className="ct_user_info_name">
                                      <h5>{item?.driver_data?.name}</h5>
                                      <small>
                                        {item?.driver_data?.car_model}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="ct_rides_date">
                                  <span className="mb-0">
                                    {item?.dateOfBooking}
                                  </span>
                                  <span className="mb-0">{item?.time}</span>
                                </div>
                              </div>
                              <div className="ct_active_link">
                                {item?.driver_status == 0 &&
                                  item?.scheduledStatus != "Scheduled" &&
                                  item?.Ride_status != 2 && (
                                    <a className="ct_active_link">Pending</a>
                                  )}
                                {item?.driver_status == 1 &&
                                  item?.scheduledStatus != "Scheduled" &&
                                  item?.Ride_status != 2 && (
                                    <a className="ct_active_link">Accept</a>
                                  )}
                                {item?.driver_status == 2 &&
                                  item?.scheduledStatus != "Scheduled" &&
                                  item?.Ride_status != 2 && (
                                    <a className="ct_active_link">Cancelled</a>
                                  )}
                                {item?.Ride_status == 2 &&
                                  item?.scheduledStatus != "Scheduled" && (
                                    <a className="ct_active_link">Completed</a>
                                  )}
                                {item?.scheduledStatus == "Scheduled" && (
                                  <a className="ct_active_link">Scheduled</a>
                                )}
                              </div>
                            </div>
                          )
                      )
                    : filterData == "all" &&
                      AllRides?.map((item) => (
                        <div
                          className={
                            active_ride == item?.id
                              ? "ct_active_ride_card ct_card_active"
                              : "ct_active_ride_card"
                          }
                          onClick={() => userDetails(item?.id)}
                        >
                          <div className="d-flex align-items-center flex-wrap gap-5">
                            <div className="d-flex align-items-center flex-wrap gap-3">
                              <div className="ct_user_img_circle">
                                {console.log(item?.driver_data?.profile_image)}
                                {item?.driver_data?.profile_image != "" &&
                                item?.driver_data?.profile_image != "0" ? (
                                  <img src={item?.driver_data?.profile_image} />
                                ) : (
                                  <img src={ellipse} />
                                )}
                              </div>
                              <div className="d-flex align-items-center gap-4">
                                <div className="ct_user_info_name">
                                  <h5>{item?.driver_data?.name}</h5>
                                  <small>{item?.driver_data?.car_model}</small>
                                </div>
                              </div>
                            </div>
                            <div className="ct_rides_date">
                              <span className="mb-0">
                                {item?.dateOfBooking}
                              </span>
                              <span className="mb-0">{item?.time}</span>
                            </div>
                          </div>
                          <div className="ct_active_link">
                            {item?.driver_status == 0 &&
                              item?.scheduledStatus != "Scheduled" &&
                              item?.Ride_status != 2 && (
                                <a className="ct_active_link">Pending</a>
                              )}
                            {item?.driver_status == 1 &&
                              item?.scheduledStatus != "Scheduled" &&
                              item?.Ride_status != 2 && (
                                <a className="ct_active_link">Accept</a>
                              )}
                            {item?.driver_status == 2 &&
                              item?.scheduledStatus != "Scheduled" &&
                              item?.Ride_status != 2 && (
                                <a className="ct_active_link">Cancelled</a>
                              )}
                            {item?.Ride_status == 2 &&
                              item?.scheduledStatus != "Scheduled" && (
                                <a className="ct_active_link">Completed</a>
                              )}
                            {item?.scheduledStatus == "Scheduled" && (
                              <a className="ct_active_link">Scheduled</a>
                            )}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>

            {AllRides?.length != 0 && filterData == "all" ? (
              <div className="col-lg-4 mb-4">
                <div className="ct_active_rides_cnt">
                  <h4 className="text-center ">Ride Details</h4>
                </div>
                {isLoader == true && (
                  <div className="loader">
                    <svg
                      className="car"
                      width="102"
                      height="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(2 1)"
                        stroke="#002742"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          className="car__body"
                          d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                          stroke-width="3"
                        />
                        <ellipse
                          className="car__wheel--left"
                          stroke-width="3.2"
                          fill="#FFF"
                          cx="83.493"
                          cy="30.25"
                          rx="6.922"
                          ry="6.808"
                        />
                        <ellipse
                          className="car__wheel--right"
                          stroke-width="3.2"
                          fill="#FFF"
                          cx="46.511"
                          cy="30.25"
                          rx="6.922"
                          ry="6.808"
                        />
                        <path
                          className="car__line car__line--top"
                          d="M22.5 16.5H2.475"
                          stroke-width="3"
                        />
                        <path
                          className="car__line car__line--middle"
                          d="M20.5 23.5H.4755"
                          stroke-width="3"
                        />
                        <path
                          className="car__line car__line--bottom"
                          d="M25.5 9.5h-19"
                          stroke-width="3"
                        />
                      </g>
                    </svg>
                  </div>
                )}
                {isLoader == false && filterData == "all" ? (
                  <div className="ct_rides_detail_box">
                    <span className="ct_grey_text">Driver</span>
                    <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                      <div className="d-flex align-items-center flex-wrap gap-3 ">
                        <div className="ct_user_img_circle">
                          {riderDetails?.driver_data?.profile_image != "" &&
                          riderDetails?.driver_data?.profile_image != "0" ? (
                            <img
                              src={riderDetails?.driver_data?.profile_image}
                            />
                          ) : (
                            <img src={ellipse} />
                          )}
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>{riderDetails?.driver_data?.name}</h5>
                            <small>
                              {riderDetails?.driver_data?.car_model}
                              <br />
                              {/* {riderDetails?.driver_data?.phone_no} */}
                              {riderDetails?.driver_data?.phone_no &&
                              riderDetails?.driver_data?.phone_no !==
                                "undefined"
                                ? riderDetails?.driver_data?.phone_no
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        {/* {riderDetails?.driver_status == 0  && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Pending</a>} */}
                        {/* {riderDetails?.driver_status == 1  && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Accept</a>} */}
                        {/* {riderDetails?.driver_status == 2  && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Cancelled</a>} */}
                        {/* {riderDetails?.Ride_status == 2  && <a className="ct_yellow_text">Complete</a>} */}
                        {riderDetails?.driver_status == 2 && (
                          <a
                            className="ct_yellow_btn mt-2"
                            data-bs-toggle="modal"
                            data-bs-target="#ct_reassign_modal"
                            onClick={() =>
                              getModalRides(
                                riderDetails?.driver_data?.id,
                                riderDetails?.id
                              )
                            }
                          >
                            Reassign Cab1
                            {console.log(riderDetails, "riderDetails")}
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="ct_border_btoom  py-3">
                      <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                        <p className="mb-0 ct_grey_text">Date & Time</p>
                        <p>
                          <span className="mb-0">
                            {" "}
                            {riderDetails?.dateOfBooking}{" "}
                          </span>
                          <span className="mb-0"> {riderDetails?.time} </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap mt-3  ">
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>Current Location</h5>
                          <small>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-geo-alt"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            {riderDetails.start}
                          </small>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        <img src={from_to} alt="img/from_to.png" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap  ">
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>Soft Bank Buildings</h5>
                          <small>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-geo-alt"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            {riderDetails?.destination}
                          </small>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        <img src={from_to_red} alt="img/from_to_red.png" />
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="ct_grey_text mb-3 d-block">User</span>
                      <div className="d-flex align-items-center flex-wrap gap-3">
                        <div className="ct_user_img_circle">
                          {riderDetails?.profile_image != "" &&
                          riderDetails?.profile_image != "0" ? (
                            <img src={riderDetails?.profile_image} />
                          ) : (
                            <img src={girl_png} />
                          )}
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>{riderDetails?.name}</h5>
                            <small>
                              {/* {riderDetails?.phone_no} */}                            
                              {riderDetails?.phone_no &&
                              riderDetails?.phone_no !==
                                "undefined"
                                ? riderDetails?.phone_no
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ct_slect_option_w">
                      <select
                        className="form-control"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="Accept">Accept</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Pending">Pending</option>
                      </select>
                    </div>
                    <div className="mt-4">
                      <button
                        className="ct_custome_btn py-2 ct_btn_border_10"
                        onClick={() => UpdateRides(riderDetails?.id)}
                      >
                        Update Ride
                      </button>
                    </div>
                  </div>
                ) : (
                  filterData == riderDetails?.driver_status && (
                    <div className="ct_rides_detail_box">
                      <span className="ct_grey_text">Driver</span>
                      <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                        <div className="d-flex align-items-center flex-wrap gap-3 ">
                          <div className="ct_user_img_circle">
                            {riderDetails?.driver_data?.profile_image != "" &&
                            riderDetails?.driver_data?.profile_image != "0" ? (
                              <img
                                src={riderDetails?.driver_data?.profile_image}
                              />
                            ) : (
                              <img src={ellipse} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.driver_data?.name}</h5>
                              <small>
                                {riderDetails?.driver_data?.car_model}
                                <br />
                                {/* {riderDetails?.driver_data?.phone_no} */}
                                {riderDetails?.driver_data?.phone_no &&
                              riderDetails?.driver_data?.phone_no !==
                                "undefined"
                                ? riderDetails?.driver_data?.phone_no
                                : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          {riderDetails?.driver_status == 0 &&
                            riderDetails?.Ride_status != 2 && (
                              <a className="ct_yellow_text">Pending</a>
                            )}
                          {riderDetails?.driver_status == 1 &&
                            riderDetails?.Ride_status != 2 && (
                              <a className="ct_yellow_text">Accept</a>
                            )}
                          {riderDetails?.driver_status == 2 &&
                            riderDetails?.Ride_status != 2 && (
                              <a className="ct_yellow_text">Cancelled</a>
                            )}
                          {riderDetails?.Ride_status == 2 &&
                            riderDetails?.Ride_status != 2 && (
                              <a className="ct_yellow_text">Completed</a>
                            )}
                          {riderDetails?.driver_status == 2 && (
                            <a
                              className="ct_yellow_btn mt-2"
                              data-bs-toggle="modal"
                              data-bs-target="#ct_reassign_modal"
                              onClick={() =>
                                getModalRides(
                                  riderDetails?.driver_data?.id,
                                  riderDetails?.id
                                )
                              }
                            >
                              Reassign Cab
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="ct_border_btoom  py-3">
                        <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                          <p className="mb-0 ct_grey_text">Date & Time</p>
                          <p>
                            <span className="mb-0">
                              {" "}
                              {riderDetails?.dateOfBooking}{" "}
                            </span>
                            <span className="mb-0"> {riderDetails?.time} </span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap mt-3  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Current Location</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails?.start}
                            </small>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <img src={from_to} alt="img/from_to.png" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Soft Bank Buildings</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails?.destination}
                            </small>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <img src={from_to_red} alt="img/from_to_red.png" />
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="ct_grey_text mb-3 d-block">User</span>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <div className="ct_user_img_circle">
                            {riderDetails?.profile_image != "" &&
                            riderDetails?.profile_image != "0" ? (
                              <img src={riderDetails?.profile_image} />
                            ) : (
                              <img src={girl_png} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.name}</h5>
                              <small>
                                {/* {riderDetails?.phone_no} */}
                              {riderDetails?.phone_no &&
                              riderDetails?.phone_no !==
                                "undefined"
                                ? riderDetails?.phone_no
                                : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ct_slect_option_w">
                        <select
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="Accept">Accept</option>
                          <option value="Completed">Completed</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </div>
                      <div className="mt-4">
                        <button
                          className="ct_custome_btn py-2 ct_btn_border_10"
                          onClick={() => UpdateRides(riderDetails?.id)}
                        >
                          Update Ride
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              searchData?.length != 0 &&
              filterData != "all" && (
                <div className="col-lg-4 mb-4">
                  <div className="ct_active_rides_cnt">
                    <h4 className="text-center ">Ride Details</h4>
                  </div>
                  {isLoader == true && (
                    <div className="loader">
                      <svg
                        className="car"
                        width="102"
                        height="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          transform="translate(2 1)"
                          stroke="#002742"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            className="car__body"
                            d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                            stroke-width="3"
                          />
                          <ellipse
                            className="car__wheel--left"
                            stroke-width="3.2"
                            fill="#FFF"
                            cx="83.493"
                            cy="30.25"
                            rx="6.922"
                            ry="6.808"
                          />
                          <ellipse
                            className="car__wheel--right"
                            stroke-width="3.2"
                            fill="#FFF"
                            cx="46.511"
                            cy="30.25"
                            rx="6.922"
                            ry="6.808"
                          />
                          <path
                            className="car__line car__line--top"
                            d="M22.5 16.5H2.475"
                            stroke-width="3"
                          />
                          <path
                            className="car__line car__line--middle"
                            d="M20.5 23.5H.4755"
                            stroke-width="3"
                          />
                          <path
                            className="car__line car__line--bottom"
                            d="M25.5 9.5h-19"
                            stroke-width="3"
                          />
                        </g>
                      </svg>
                    </div>
                  )}
                  {isLoader == false && filterData == "all" ? (
                    <div className="ct_rides_detail_box">
                      <span className="ct_grey_text">Driver</span>
                      <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                        <div className="d-flex align-items-center flex-wrap gap-3 ">
                          <div className="ct_user_img_circle">
                            {riderDetails?.driver_data?.profile_image != "" &&
                            riderDetails?.driver_data?.profile_image != "0" ? (
                              <img
                                src={riderDetails?.driver_data?.profile_image}
                              />
                            ) : (
                              <img src={ellipse} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.driver_data?.name}</h5>
                              <small>
                                {riderDetails?.driver_data?.car_model}
                                <br />
                                {/* {riderDetails?.driver_data?.phone_no} */}
                                {riderDetails?.driver_data?.phone_no &&
                              riderDetails?.driver_data?.phone_no !==
                                "undefined"
                                ? riderDetails?.driver_data?.phone_no
                                : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          {/* {riderDetails?.driver_status == 0 && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Pending</a>}
                                                {riderDetails?.driver_status == 1 && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Accept</a>}
                                                {riderDetails?.driver_status == 2 && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Cancelled</a>}
                                                {riderDetails?.Ride_status == 2 && <a className="ct_yellow_text">Complete</a>} */}
                          {riderDetails?.driver_status == 2 && (
                            <a
                              className="ct_yellow_btn mt-2"
                              data-bs-toggle="modal"
                              data-bs-target="#ct_reassign_modal"
                              onClick={() =>
                                getModalRides(
                                  riderDetails?.driver_data?.id,
                                  riderDetails?.id
                                )
                              }
                            >
                              Reassign Cab
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="ct_border_btoom  py-3">
                        <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                          <p className="mb-0 ct_grey_text">Date & Time</p>
                          <p>
                            <span className="mb-0">
                              {" "}
                              {riderDetails?.dateOfBooking}{" "}
                            </span>
                            <span className="mb-0"> {riderDetails?.time} </span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap mt-3  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Current Location</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails?.start}
                            </small>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <img src={from_to} alt="img/from_to.png" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Soft Bank Buildings</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails?.destination}
                            </small>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <img src={from_to_red} alt="img/from_to_red.png" />
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="ct_grey_text mb-3 d-block">User</span>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <div className="ct_user_img_circle">
                            {riderDetails?.profile_image != "" &&
                            riderDetails?.profile_image != "0" ? (
                              <img src={riderDetails?.profile_image} />
                            ) : (
                              <img src={girl_png} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.name}</h5>
                              <small>
                                {/* {riderDetails?.phone_no} */}
                                {riderDetails?.phone_no &&
                              riderDetails?.phone_no !==
                                "undefined"
                                ? riderDetails?.phone_no
                                : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ct_slect_option_w">
                        <select
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Accept</option>
                          <option>Completed</option>
                          <option>Cancelled</option>
                          <option>Pending</option>
                        </select>
                      </div>
                      <div className="mt-4">
                        <button
                          className="ct_custome_btn py-2 ct_btn_border_10"
                          onClick={() => UpdateRides(riderDetails?.id)}
                        >
                          Update Ride
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="ct_rides_detail_box">
                      <span className="ct_grey_text">Driver</span>
                      <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                        <div className="d-flex align-items-center flex-wrap gap-3 ">
                          <div className="ct_user_img_circle">
                            {riderDetails?.driver_data?.profile_image != "" &&
                            riderDetails?.driver_data?.profile_image != "0" ? (
                              <img
                                src={riderDetails?.driver_data?.profile_image}
                              />
                            ) : (
                              <img src={ellipse} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.driver_data?.name}</h5>
                              <small>
                                {riderDetails?.driver_data?.car_model}
                                <br />
                                {/* {riderDetails?.driver_data?.phone_no} */}
                                {riderDetails?.driver_data?.phone_no &&
                              riderDetails?.driver_data?.phone_no !==
                                "undefined"
                                ? riderDetails?.driver_data?.phone_no
                                : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          {/* {riderDetails?.driver_status == 0 && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Pending</a>}
                                                {riderDetails?.driver_status == 1 && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Accept</a>}
                                                {riderDetails?.driver_status == 2 && riderDetails?.Ride_status != 2 && <a className="ct_yellow_text">Cancelled</a>}
                                                {riderDetails?.Ride_status == 2 && <a className="ct_yellow_text">Complete</a>} */}
                          {riderDetails?.driver_status == 2 && (
                            <a
                              className="ct_yellow_btn mt-2"
                              data-bs-toggle="modal"
                              data-bs-target="#ct_reassign_modal"
                              onClick={() =>
                                getModalRides(
                                  riderDetails?.driver_data?.id,
                                  riderDetails?.id
                                )
                              }
                            >
                              Reassign Cab
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="ct_border_btoom  py-3">
                        <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                          <p className="mb-0 ct_grey_text">Date & Time</p>
                          <p>
                            <span className="mb-0">
                              {" "}
                              {riderDetails?.dateOfBooking}{" "}
                            </span>
                            <span className="mb-0"> {riderDetails?.time} </span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap mt-3  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Current Location</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails?.start}
                            </small>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <img src={from_to} alt="img/from_to.png" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-wrap  ">
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>Soft Bank Buildings</h5>
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                              {riderDetails?.destination}
                            </small>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          <img src={from_to_red} alt="img/from_to_red.png" />
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="ct_grey_text mb-3 d-block">User</span>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <div className="ct_user_img_circle">
                            {riderDetails?.profile_image != "" &&
                            riderDetails?.profile_image != "0" ? (
                              <img src={riderDetails?.profile_image} />
                            ) : (
                              <img src={girl_png} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{riderDetails?.name}</h5>
                              <small>
                                {/* {riderDetails?.phone_no} */}
                                {riderDetails?.phone_no &&
                              riderDetails?.phone_no !==
                                "undefined"
                                ? riderDetails?.phone_no
                                : ""}

                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ct_slect_option_w">
                        <select
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Accept</option>
                          <option>Completed</option>
                          <option>Cancelled</option>
                          <option>Pending</option>
                        </select>
                      </div>
                      <div className="mt-4">
                        <button
                          className="ct_custome_btn py-2 ct_btn_border_10"
                          onClick={() => UpdateRides(riderDetails?.id)}
                        >
                          Update Ride
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
            {searchData?.length == 0 && filterData != "all" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <NoRecord lod={isLoader} />
              </div>
            ) : (
              AllRides?.length == 0 &&
              filterData == "all" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <NoRecord lod={isLoader} />
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <div></div>
      <div
        className="modal fade"
        id="ct_reassign_modal"
        tabindex="-1"
        aria-labelledby="ct_reassign_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  fill="#FEA92A"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                  {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="modal-body px-5 pb-5">
              <h5 className="mb-4">Choose Cab</h5>
              {modalData?.map(
                (item) =>
                  item?.id != modalDriver && (
                    <div
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => updateDriver(item?.id)}
                      className={
                        activeModal == item?.id
                          ? "ct_active_ride_card ct_card_active mb-3"
                          : "ct_active_ride_card mb-3"
                      }
                    >
                      <div className="d-flex align-items-center flex-wrap gap-md-5 gap-3">
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <div className="ct_user_img_circle">
                            {item?.profile_image != "" &&
                            item?.profile_image != "0" ? (
                              <img src={item?.profile_image} />
                            ) : (
                              <img src={ellipse} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{item?.name}</h5>
                              <small>{item?.car_model}</small>
                              <p className="mb-0 ct_fs_141">
                                {/* {item.phone_no} */}
                                {item?.phone_no &&
                             item?.phone_no !==
                                "undefined"
                                ? item?.phone_no
                                : ""}
                                </p>
                            </div>
                          </div>
                        </div>
                        <div className="ct_rides_date">
                          <span className="mb-0">
                            {moment(item?.created_at).format("MMM Do YYYY")}
                          </span>
                          <span className="mb-0">
                            {moment(item?.created_at).format("h:mm A")}
                          </span>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        {/* ct_green_text */}
                        {item?.driver_status == 0 && item?.Ride_status != 2 && (
                          <a className="ct_active_link">Pending</a>
                        )}
                        {item?.driver_status == 1 && item?.Ride_status != 2 && (
                          <a className="ct_active_link ">Accept</a>
                        )}
                        {item?.driver_status == 2 && item?.Ride_status != 2 && (
                          <a className="ct_active_link">Cancelled</a>
                        )}
                        {item?.Ride_status == 2 && (
                          <a className="ct_active_link">Completed</a>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Rides;
