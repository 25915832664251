import React, { useEffect, useState } from 'react'
import axios from "axios"
import girl_png from "../Images/girl_png.png"
import Navbar from './Navbar'
import { useNavigate } from "react-router-dom";
import { message, message as MESSAGE } from "antd";
import moment from 'moment'
import NoRecord from './Com/NoRecord';
// import Loader from "react-js-loader";
export const configJSON = require("../Components/Config");

function Users() {
    const [shares, setShares] = useState('')
    const [allUsers, setAllUsers] = useState([])
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState('all')
    const [updateData, setUpdateData] = useState()
    const [isLoader, setIsLoader] = useState(false);
    const [active_user, setActive_user] = useState();
    const [searchData, setSearchData] = useState([])
    const [searchDataByUser, setSearchDataByUser] = useState();

    const navigate = useNavigate()

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))
        if (token == null) {
            navigate('/login');
        } else {
            getAllUsers()
        }
    }, []);

    const getAllUsers = () => {
        setIsLoader(true)
        window.scroll(0, 0)
        axios({
            url: configJSON.baseUrl + configJSON.get_all_users,
            method: "get",
        }).then((res) => {
            console.log(res)
            setAllUsers(res?.data?.data)
            setActive_user(res?.data?.data[0]?.id)
            getUsersDetail(res?.data?.data[0]?.id)
        }).catch((err) => {
            console.log(err)
        }).finally(()=>setIsLoader(false))
    };


    const getUsersDetail = (id) => {
        setActive_user(id)
        const data = {
            "user_id": `${id}`
        }
        setIsLoader(true)
        axios({
            url: configJSON.baseUrl + configJSON.get_users_detail,
            method: "post",
            data: data
        }).then((res) => {
            console.log(res, "singledetail")
            setData(res.data.data[0])
            setUpdateData(res?.data?.data[0]?.block_user)
            setShares(res?.data?.data[0]?.shares)
            console.log(res?.data?.data[0]?.shares, "hello")
            // setIsLoader(false)
        }).catch((err) => {
            console.log(err)
            // setIsLoader(false)
        })
        .finally(()=>setIsLoader(false))
    };

    const updateDetails = (id) => {
        const data = {
            user_id: `${id}`,
            is_block: updateData,
            shares: shares,
        }
        setIsLoader(true)
        axios({
            url: configJSON.baseUrl + configJSON.update_user_detail,
            method: "post",
            data: data
        }).then((res) => {
            MESSAGE.success(res?.data?.message)
            setFilterData('all')
            getAllUsers()

        }).catch((err) => {
            console.log(err)
        })
        .finally(()=>setIsLoader(false))
    };
    
    const filterUserData = (val) => {
        setFilterData(val)
        const dummyData = [];
        if (val != 'all') {
            allUsers?.map((item) => (
                item?.block_user == val &&
                dummyData.push(item)
            ))
            setSearchData(dummyData);
            getUsersDetail(dummyData[0]?.id);
            setActive_user(dummyData[0]?.id);
        }
        else {
            const newDummyData = [];
            allUsers?.map((item) => (
                newDummyData.push(item)
            ))
            setSearchData(newDummyData);
            getUsersDetail(newDummyData[0]?.id);
            setActive_user(newDummyData[0]?.id);
        }
    }

    const onHandaleSearchvalue = (e) => {
        setSearchData([])
        setFilterData('all')
        setSearchDataByUser(e.target.value)
        const data = allUsers.filter((item) => (
            item?.name.toLowerCase().includes(e.target.value.toLowerCase()) && searchData.push(item)
        ))
        setSearchData(data);
        getUsersDetail(searchData[0]?.id);
        setAllUsers(searchData);
        setActive_user(searchData[0]?.id);
        setSearchData([])
        if (e.target.value == "")
            getAllUsers()
    }
    return (
        <>
            <section className="ct_padd_in_100">
                <Navbar data='users' />
                <div className="container-fluid ct_margin_top">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ct_search_bar">
                                <input type="text" className="form-control" value={searchDataByUser} onChange={(e) => onHandaleSearchvalue(e)} placeholder="Search" />
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <div className="ct_choose_rides_dot">
                                <ul>
                                    <li onClick={() => filterUserData('all')}>
                                        <input className="ct_radio-input" name="radio-group" checked={filterData == 'all' ? true : false} id="radio1" type="radio" />
                                        <label className="ct_radio-label" for="radio1">
                                            <span className="ct_radio-inner-circle"></span>
                                            All Users
                                        </label>
                                    </li>
                                    <li onClick={() => filterUserData('0')}>
                                        <input className="ct_radio-input" name="radio-group" checked={filterData == '0' ? true : false} id="radio2" type="radio" />
                                        <label className="ct_radio-label" for="radio2">
                                            <span className="ct_radio-inner-circle"></span>
                                            Active
                                        </label>
                                    </li>
                                    <li onClick={() => filterUserData('1')}>
                                        <input className="ct_radio-input" name="radio-group" checked={filterData == '1' ? true : false} id="radio3" type="radio" />
                                        <label className="ct_radio-label" for="radio3">
                                            <span className="ct_radio-inner-circle"></span>
                                            Blocked
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-8 mb-4">
                            <div className="ct_active_rides_cnt">
                                <h4>Users</h4>
                                {
                                    filterData !== 'all' ?
                                        allUsers?.map((ob) => (ob?.block_user == filterData &&
                                            <div style={{ marginBottom: '8px' }} className="ct_activre_rides_all_boxes" onClick={() => getUsersDetail(ob?.id)}>
                                                <div className={active_user == ob?.id ? "ct_active_ride_card ct_card_active" : "ct_active_ride_card"}>
                                                    <div className="d-flex align-items-center flex-wrap gap-5">
                                                        <div className="d-flex align-items-center flex-wrap gap-3">
                                                            <div className="ct_user_img_circle">
                                                                <div className="d-flex align-items-center gap-4 flex-wrap">
                                                                    {ob?.profile_image != "0" && ob?.profile_image != "" ?
                                                                        <img src={ob?.profile_image} />
                                                                        :
                                                                        <img src={girl_png} alt="img/girl_png.png" />
                                                                    }
                                                                    <div className="ct_user_info_name">
                                                                        <h5>{ob?.name}</h5>
                                                                        <small>{ob?.phone_no}  </small>
                                                                    </div>
                                                                    <div className="ct_rides_date">
                                                                        <span>Registered on - {moment(ob?.created_at).format('h:mm A')} </span>
                                                                        <span>Total Rides - {ob?.total_rides?.total_rides} </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ct_active_link">
                                                        {ob?.block_user == 0 && <a className="ct_green_text">Active</a>}
                                                        {ob?.block_user == 1 && <a className="ct_green_text">Blocked</a>}
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                        allUsers?.map((ob) => (
                                            <div style={{ marginBottom: '8px' }} className="ct_activre_rides_all_boxes" onClick={() => getUsersDetail(ob?.id)}>
                                                <div className={active_user == ob?.id ? "ct_active_ride_card ct_card_active" : "ct_active_ride_card"}>
                                                    <div className="d-flex align-items-center flex-wrap gap-5">
                                                        <div className="d-flex align-items-center flex-wrap gap-3">
                                                            <div className="ct_user_img_circle">
                                                                <div className="d-flex align-items-center gap-4 flex-wrap">
                                                                    {ob?.profile_image != "0" && ob?.profile_image != "" ?
                                                                        <img src={ob?.profile_image} />
                                                                        :
                                                                        <img src={girl_png} alt="img/girl_png.png" />
                                                                    }
                                                                    <div className="ct_user_info_name">
                                                                        <h5>{ob?.name}</h5>
                                                                        <small>{ob?.phone_no}</small>
                                                                    </div>
                                                                    <div className="ct_rides_date">
                                                                        <span>Registered on - {moment(ob?.created_at).format('h:mm A')} </span>
                                                                        <span>Total Rides - {ob?.total_rides?.total_rides} </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ct_active_link">
                                                        {ob?.block_user == 0 && <a className="ct_green_text">Active</a>}
                                                        {ob?.block_user == 1 && <a className="ct_green_text">Blocked</a>}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4 ">
                            {/* <div className="ct_active_rides_cnt">
                                <h4 className="text-center "></h4>
                            </div> */}
                            <div className={filterData != 'all' ? searchData?.length != 0 ? "ct_rides_detail_box" : "" : allUsers?.length != 0 ? "ct_rides_detail_box" : ""}>
                                {filterData == 'all' && allUsers?.length != 0 ?
                                    <div onClick={() => navigate('/userride', { state: { id: data.id } })} className="ct_yellow_head_bg d-flex align-items-center justify-content-between ct_see_all_ride_cnt">
                                        <div className="" >
                                            <h4 >See All Rides</h4>
                                        </div>
                                        <div className="ct_right_icon">
                                            <i className="fa-solid fa-arrow-right"></i>
                                        </div>
                                    </div>
                                    : filterData == data?.block_user &&
                                    <div onClick={() => navigate('/userride', { state: { id: data.id } })} className="ct_yellow_head_bg d-flex align-items-center justify-content-between ct_see_all_ride_cnt">
                                        <div className="" >
                                            <h4 >See All Rides</h4>
                                        </div>
                                        <div className="ct_right_icon">
                                            <i className="fa-solid fa-arrow-right"></i>
                                        </div>
                                    </div>
                                }
                                {isLoader == true &&
                                    <div className="loader">
                                        <svg className="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(2 1)" stroke="#002742" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                <path className="car__body" d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01" stroke-width="3" />
                                                <ellipse className="car__wheel--left" stroke-width="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808" />
                                                <ellipse className="car__wheel--right" stroke-width="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808" />
                                                <path className="car__line car__line--top" d="M22.5 16.5H2.475" stroke-width="3" />
                                                <path className="car__line car__line--middle" d="M20.5 23.5H.4755" stroke-width="3" />
                                                <path className="car__line car__line--bottom" d="M25.5 9.5h-19" stroke-width="3" />
                                            </g>
                                        </svg>
                                    </div>
                                }
                                {isLoader == false && <div className="ct_profie_card">
                                    {filterData == 'all' && allUsers?.length != 0 ?
                                        <form>
                                            <div className="position-relative d-block mx-auto mt-4 pt-3 mb-4" style={{ "width": "fit-content" }}>
                                                <div className="ct_circle">
                                                    {data?.profile_image != "0" && data?.profile_image != "" ?
                                                        <img className="ct_profile_pic" src={data?.profile_image} />
                                                        :
                                                        <img className="ct_profile_pic" src={girl_png} alt="img/girl_png.png" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                    <label className='mb-2'>Username</label>
                                                <input type="text" value={data.name} className="form-control" />
                                            </div>
                                            <div className="form-group mb-4">
                                            <label className='mb-2'>Phone Number</label>
                                                <input type="text" value={data.phone_no} className="form-control" />
                                            </div>

                                            <div className="form-group mb-4">
                                            <label className='mb-2'>Shares</label>

                                                <input type="number" placeholder='Enter Shares' value={shares != null ? shares : ''} onChange={(e) => setShares(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="ct_choose_rides_dot">
                                                <ul>
                                                    <li onClick={() => setUpdateData('0')}>
                                                        <input className="ct_radio-input" checked={updateData == 0 ? true : false} name="radio-group" id="radio5" type="radio" />
                                                        <label className="ct_radio-label">
                                                            <span className="ct_radio-inner-circle"></span>
                                                            Active
                                                        </label>
                                                    </li>
                                                    <li onClick={() => setUpdateData('1')}>
                                                        <input className="ct_radio-input" checked={updateData == 1 ? true : false} name="radio-group" id="radio4" type="radio" />
                                                        <label className="ct_radio-label">
                                                            <span className="ct_radio-inner-circle"></span>
                                                            Blocked
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-4 pt-3">
                                                <button className="ct_custome_btn py-2 ct_btn_border_10" type='button' onClick={() => updateDetails(data.id)} >Save</button>
                                            </div>
                                        </form> : filterData == data?.block_user && allUsers?.length != 0 &&
                                        <form >
                                            <div className="position-relative d-block mx-auto mt-4 pt-3 mb-4" style={{ "width": "fit-content" }}>
                                                <div className="ct_circle">
                                                    {data?.profile_image != "0" && data?.profile_image != "" ?
                                                        <img className="ct_profile_pic" src={data?.profile_image} />
                                                        :
                                                        <img className="ct_profile_pic" src={girl_png} alt="img/girl_png.png" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                 <label className='mb-2'>Username</label>
                                                <input type="text" readOnly value={data.name} className="form-control" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className='mb-2'>Phone Number</label>
                                                <input type="text" readOnly value={data.phone_no} className="form-control" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className='mb-2'>Shares</label>
                                                <input type="number" placeholder='Enter Shares' value={shares != null ? shares : ''} onChange={(e) => setShares(e.target.value)} className="form-control" />
                                            </div>
                                            <div className="ct_choose_rides_dot">
                                                <ul>
                                                    <li onClick={() => setUpdateData('0')}>
                                                        <input className="ct_radio-input" checked={updateData == 0 ? true : false} name="radio-group" id="radio5" type="radio" />
                                                        <label className="ct_radio-label">
                                                            <span className="ct_radio-inner-circle"></span>
                                                            Active
                                                        </label>
                                                    </li>
                                                    <li onClick={() => setUpdateData('1')}>
                                                        <input className="ct_radio-input" checked={updateData == 1 ? true : false} name="radio-group" id="radio4" type="radio" />
                                                        <label className="ct_radio-label">
                                                            <span className="ct_radio-inner-circle"></span>
                                                            Blocked
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-4 pt-3">
                                                <button className="ct_custome_btn py-2 ct_btn_border_10" type='button' onClick={() => updateDetails(data.id)} >Save</button>
                                            </div>
                                        </form>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        {searchData?.length == 0 && filterData != 'all' ?
                            <div style={{ display: "flex", justifyContent: "center" }}>
                               < NoRecord lod={isLoader}/>
                            </div>
                            :
                            allUsers?.length == 0 && filterData == 'all' &&
                            <div style={{ display: "flex", justifyContent: "center" }}>
                               < NoRecord lod={isLoader}/>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Users
